import React from "react";
import styled from "styled-components";
import axios from "axios";
import { IWithGoogleReCaptchaProps, withGoogleReCaptcha } from "react-google-recaptcha-v3";
import ComponentBase from "../component-base";
import { colors, breakpoints, fonts, styles } from "../assets/variables/style-variables";
import { Button } from "./button";
import logger from "../services/logger";
import constants from "../constants";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 24px;

  @media (min-width: ${breakpoints.vp3}) {
    margin-top: 66px;
  }

  input[type="text"],
  input[type="email"] {
    display: block;
    width: 100%;
    margin-bottom: 8px;
    padding: 12px 18px;
    box-sizing: border-box;
    color: ${colors.gray.dark};
    background-color: ${colors.gray.lightExtra};
    border: 1px solid ${colors.gray.light};
    border-radius: ${styles.borderRadius};
    font-family: ${fonts.base};
    font-size: 16px;

    &:last-of-type {
      margin-bottom: 24px;
    }

    &::placeholder {
      color: ${colors.gray.base};
    }

    &:focus {
      border-color: ${colors.gray.lightMid};
      background-color: ${colors.white};
    }
  }
`;

const SubmittedMessage = styled.span`
  display: block;
  height: 18px;
  margin-top: 24px;
`;

enum formSubmissionStatus {
  isNotSubmitted,
  isSubmitted,
  isSuccessful,
  isFailed,
}
interface INewsletterFormStates {
  formSubmissionStatus: formSubmissionStatus;
}

class NewsletterFormComponent extends ComponentBase<Partial<IWithGoogleReCaptchaProps>, INewsletterFormStates> {
  private form: React.RefObject<HTMLFormElement>;

  constructor(props: Partial<IWithGoogleReCaptchaProps>) {
    super(props);

    this.form = React.createRef();

    this.state = {
      formSubmissionStatus: formSubmissionStatus.isNotSubmitted,
    };
  }

  public render(): JSX.Element {
    return (
      <Form id="form" ref={this.form} onSubmit={this.handleSignUpFormSubmit}>
        <input
          type="text"
          placeholder={this.str.subFirstNameText}
          name="first_name"
          pattern="[a-zA-Z0-9\s]+"
          required
          onBlur={this.handleTextInputBlur}
        />
        <input
          type="text"
          placeholder={this.str.subLastNameText}
          name="last_name"
          pattern="[a-zA-Z0-9\s]+"
          required
          onBlur={this.handleTextInputBlur}
        />
        <input
          type="email"
          placeholder={this.str.subEmailText}
          name="email"
          required
          onBlur={this.handleTextInputBlur}
        />
        <Button
          type="submit"
          disabled={
            this.state.formSubmissionStatus === formSubmissionStatus.isSubmitted ||
            this.state.formSubmissionStatus === formSubmissionStatus.isSuccessful
          }
        >
          {this.getSubmitButtonValue()}
        </Button>
        <SubmittedMessage>{this.getSubmissionStatusMessage()}</SubmittedMessage>
      </Form>
    );
  }

  private getSubmitButtonValue = (): string => {
    let value: string;

    switch (this.state.formSubmissionStatus) {
      case formSubmissionStatus.isNotSubmitted:
        value = this.str.subSubmitSubscribe;
        break;
      case formSubmissionStatus.isSubmitted:
        value = this.str.subSubmitSubmitting;
        break;
      case formSubmissionStatus.isSuccessful:
        value = this.str.subSubmitSuccess;
        break;
      case formSubmissionStatus.isFailed:
        value = this.str.subSubmitFailed;
        break;
    }

    return value;
  };

  private getSubmissionStatusMessage = (): string => {
    let message: string;

    switch (this.state.formSubmissionStatus) {
      case formSubmissionStatus.isNotSubmitted:
      case formSubmissionStatus.isSubmitted:
        message = "";
        break;
      case formSubmissionStatus.isSuccessful:
        message = this.str.subStatusSuccess;
        break;
      case formSubmissionStatus.isFailed:
        message = this.str.subStatusFailed;
        break;
    }

    return message;
  };

  private handleTextInputBlur = (e: React.ChangeEvent<HTMLInputElement>): void => {
    e.target.value = e.target.value.trim();
  };

  private handleSignUpFormSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    logger.log(constants.logging.SUBSCRIBE, "submit");
    e.preventDefault();

    if (!this.form.current) {
      return;
    }

    this.setState({
      formSubmissionStatus: formSubmissionStatus.isSubmitted,
    });

    this.submitEmail(this.form.current);
  };

  private submitEmail = async (formElement: HTMLFormElement): Promise<void> => {
    // TODO: Consider getting multiple tokens to verify recaptcha twice
    const recaptchaToken = await this.props.googleReCaptchaProps?.executeRecaptcha?.("homepage");

    if (!recaptchaToken) {
      this.setState({
        formSubmissionStatus: formSubmissionStatus.isFailed,
      });
      logger.logError(constants.logging.SUBSCRIBE, new Error("Failed to retrieve reCAPTCHA token"));
      return;
    }

    const formData: FormData = new FormData(formElement);
    const urlSearchParams: URLSearchParams = new URLSearchParams();

    urlSearchParams.append("first_name", formData.get("first_name") as string);
    urlSearchParams.append("last_name", formData.get("last_name") as string);
    urlSearchParams.append("email", formData.get("email") as string);
    urlSearchParams.append("oid", "00D4W000001WxHc");
    urlSearchParams.append("recaptcha", recaptchaToken);
    urlSearchParams.append("retUrl", "");

    const url = `${this.baseFunctionsUrl}/HttpEmailSubmission?${urlSearchParams.toString()}`;

    try {
      await axios.post(url);
      this.setState({
        formSubmissionStatus: formSubmissionStatus.isSuccessful,
      });
      logger.log(constants.logging.SUBSCRIBE, "success");
    } catch (error) {
      this.setState({
        formSubmissionStatus: formSubmissionStatus.isFailed,
      });
      logger.logError(constants.logging.SUBSCRIBE, error as Error);
    }
  };
}

export const NewsletterForm = withGoogleReCaptcha(NewsletterFormComponent);
