export default {
  VERSION: "1.0",
  DEFAULT_LANG: "en",
  GOOGLE_ANALYTICS_CODE: "UA-186050432-1",
  DEFAULT_BASE_FUNCTIONS_URL: "http://localhost:7071/api",
  RECAPTCHA_SITE_KEY: "6Lf8aBgfAAAAAGWglRxmBwy2joK3y-RelV-H17jQ",
  RECAPTCHA_SCORE_THRESHOLD_DEFAULT: 0.5,

  // logging constants
  logging: {
    LANG_INIT: "LangInit",
    RECAPTCHA: "Recaptcha",
    DONATE: "Donate",
    SUBSCRIBE: "Subscribe",
  },
};
