import React from "react";
import { BrowserRouter, Switch, Route, Redirect, RouteComponentProps } from "react-router-dom";
import styled from "styled-components";
import throttle from "lodash.throttle";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import axios from "axios";
import { colors, fonts, breakpoints } from "./assets/variables/style-variables";
import "./assets/styles/app.css";
import EnStrings from "./strings/en";
import IdStrings from "./strings/id";
import logger from "./services/logger";
import constants from "./constants";
import { Nav } from "./components/nav";
import { Home } from "./components/pages/home";
import { Nonprofits } from "./components/pages/nonprofits";
import { NonprofitProfile } from "./components/nonprofit-profile";
import { AmazonSmile } from "./components/pages/amazonsmile";
import { Privacy } from "./components/pages/privacy";
import { Terms } from "./components/pages/terms";
import { Footer } from "./components/footer";
import { Donation } from "./components/pages/donation";
import { DonationReceipt } from "./components/donation-receipt";

//#region Styled Components

interface IAppContainerProps {
  isScrollEnabled: boolean;
}

const AppContainer = styled.div<IAppContainerProps>`
  height: 100vh;
  color: ${colors.gray.dark};
  perspective: 8px;
  perspective-origin: 0 0;
  overflow-x: hidden;
  overflow-y: ${(props) => (props.isScrollEnabled ? "scroll" : "hidden")};

  a {
    text-decoration: none;
  }

  p,
  li {
    margin-bottom: 24px;
    font-family: ${fonts.base};
    font-size: 16px;
    line-height: 28px;
    white-space: pre-wrap;

    @media (min-width: ${breakpoints.vp2}) {
      font-size: 18px;
      line-height: 32px;
    }

    a {
      color: ${colors.red.base};

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }
`;

const TopAnchorPoint = styled.div`
  position: fixed;
  top: 0;
`;

//#endregion Styled Components

interface AppStates {
  isScrolledToTop: boolean;
  isScrollEnabled: boolean;
  isRecaptchaBadgeVisible: boolean;
}

interface AppProps {
  routeProps: RouteComponentProps;
}

class App extends React.Component<AppProps, AppStates> {
  private appContainer: React.RefObject<HTMLDivElement>;
  private lang: string;
  private recaptchaBadgeClassName = "grecaptcha-badge";

  constructor(props: AppProps) {
    super(props);

    this.state = {
      isScrolledToTop: true,
      isScrollEnabled: true,
      isRecaptchaBadgeVisible: false,
    };

    this.appContainer = React.createRef();

    logger.logPageView(window.location.pathname + window.location.search);

    // Initialize site strings
    this.lang = constants.DEFAULT_LANG;
    try {
      const cookies = document.cookie.split("; ");
      const langCookie = cookies.find((cookie) => cookie.indexOf("lang=") === 0);
      if (langCookie) {
        this.lang = langCookie.replace("lang=", "");
      }
      (window as any).str = this.lang === "id" ? IdStrings : EnStrings;
      logger.log(constants.logging.LANG_INIT, this.lang);
    } catch (e) {
      // @ts-ignore
      logger.logError(constants.logging.LANG_INIT, e);
    }

    // Log page navigation
    props.routeProps.history.listen((location) => {
      logger.logPageView(location.pathname + location.search);
    });

    if (sessionStorage.getItem("session")) {
      axios.defaults.headers.common["session"] = sessionStorage.getItem("session");
    }
  }

  public componentDidMount(): void {
    this.handleWindowScroll();
    this.handleAppScroll();

    this.appContainer.current?.addEventListener("scroll", this.throttledAppScrollHandler);

    window.addEventListener("scroll", this.throttledWindowScrollHandler);
  }

  public componentWillUnmount(): void {
    this.appContainer.current?.removeEventListener("scroll", this.throttledAppScrollHandler);

    window.removeEventListener("scroll", this.throttledWindowScrollHandler);
  }

  public render(): JSX.Element {
    return (
      <AppContainer id="app" className="App" ref={this.appContainer} isScrollEnabled={this.state.isScrollEnabled}>
        <Nav isScrolledToTop={this.state.isScrolledToTop} onNavToggle={this.handleNavToggle} />
        <TopAnchorPoint id="home" />
        <GoogleReCaptchaProvider reCaptchaKey={constants.RECAPTCHA_SITE_KEY} language={this.lang}>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/nonprofits">
              <Nonprofits />
            </Route>
            <Route path="/nonprofits/:nonprofit">
              <NonprofitProfile />
            </Route>
            <Route path="/donate">
              <Donation />
            </Route>
            <Route path="/donation/:id">
              <DonationReceipt />
            </Route>
            <Route path="/amazonsmile">
              <AmazonSmile />
            </Route>
            <Route path="/privacy">
              <Privacy />
            </Route>
            <Route path="/terms">
              <Terms />
            </Route>
            <Redirect to="/" />
          </Switch>
        </GoogleReCaptchaProvider>
        <Footer />
      </AppContainer>
    );
  }

  private handleAppScroll = (): void => {
    const appContainer: HTMLDivElement | null = this.appContainer.current;

    if (!appContainer) {
      return;
    }

    const scrollTop: number = appContainer.scrollTop;
    const navScrollThreshold: number = window.innerHeight * 0.1;
    const recaptchaBadgeScrollThreshold: number = window.innerHeight * 1.5;
    const isScrolledToBottom: boolean =
      scrollTop + appContainer.getBoundingClientRect().height >= appContainer.scrollHeight;

    if (scrollTop > navScrollThreshold && this.state.isScrolledToTop) {
      this.setState({
        isScrolledToTop: false,
      });
    } else if (scrollTop <= navScrollThreshold && !this.state.isScrolledToTop) {
      this.setState({
        isScrolledToTop: true,
      });
    }

    // Toggle reCaptcha badge visibility
    if ((scrollTop > recaptchaBadgeScrollThreshold || isScrolledToBottom) && !this.state.isRecaptchaBadgeVisible) {
      this.showRecaptchaBadge();
    } else if (scrollTop <= recaptchaBadgeScrollThreshold && this.state.isRecaptchaBadgeVisible) {
      this.hideRecaptchaBadge();
    }
  };

  private throttledAppScrollHandler = throttle(this.handleAppScroll, 200);

  private handleWindowScroll = (): void => {
    // Mobile Safari and Firefox might scroll the window beyond the viewport when the on-screen keyboard is activated
    // This scrolls the window back to its initial position and scrolls the app container instead
    // This ensures the nav is always visible
    if (window.scrollY > 0) {
      this.appContainer.current?.scrollBy(0, window.scrollY);
      window.scroll(0, 0);
    }
  };

  private throttledWindowScrollHandler = throttle(this.handleWindowScroll, 200);

  private handleNavToggle = (isNavExpanded: boolean): void => {
    this.setState({
      isScrollEnabled: !isNavExpanded,
    });
  };

  private showRecaptchaBadge = (): void => {
    const badge: HTMLElement = document.getElementsByClassName(this.recaptchaBadgeClassName)[0] as HTMLElement;

    if (badge) {
      badge.style.right = "-186px";

      // Only need to this once. Initial style is set in app.css.
      badge.style.opacity = "1";
      badge.style.pointerEvents = "auto";
    }

    this.setState({ isRecaptchaBadgeVisible: true });
  };

  private hideRecaptchaBadge = (): void => {
    const badge: HTMLElement = document.getElementsByClassName(this.recaptchaBadgeClassName)[0] as HTMLElement;

    if (badge) {
      badge.style.right = `-${badge.getBoundingClientRect().width + 2}px`;
    }

    this.setState({ isRecaptchaBadgeVisible: false });
  };
}

export default class AppWrapper extends React.Component {
  render(): JSX.Element {
    return (
      <BrowserRouter>
        <Route path="*" render={(props) => <App routeProps={props} />} />
      </BrowserRouter>
    );
  }
}
