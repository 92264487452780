import React from "react";
import styled from "styled-components";
import ComponentBase from "../../component-base";
import { colors } from "../../assets/variables/style-variables";
import { Page, Grid, Column } from "../grid";
import { Hero } from "../hero";
import { SectionTitle } from "../section-title";
import heroImage720 from "../../assets/images/heroes/hero-paddy-720.jpg";
import heroImage1080 from "../../assets/images/heroes/hero-paddy-1080.jpg";
import heroImage1440 from "../../assets/images/heroes/hero-paddy-1440.jpg";

const StyledPage = styled(Page)`
  ol li {
    margin-left: 36px;
    padding-left: 4px;
    list-style-type: decimal;

    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }
`;

export class Privacy extends ComponentBase {
  public render(): JSX.Element {
    return (
      <>
        <Hero
          headingText={this.str.privacyHero}
          backgroundPosition="center bottom"
          alt={this.str.privacyHeroImgAlt}
          src={heroImage1080}
          srcVp0={heroImage720}
          srcVp5={heroImage1440}
        />
        <StyledPage backgroundColor={colors.gray.light}>
          <Grid applyPadding={true}>
            <Column span={[12, 12, 8]}>
              <SectionTitle text={this.str.privacyIntroHeading} />
              <p>{this.str.privacyIntro}</p>
              <SectionTitle text={this.str.privacySection1Heading} />
              <p>{this.str.privacySection1Paragraph1}</p>
              <ol>
                <li>
                  <div>{this.str.privacySection1List1Item1}</div>
                </li>
                <li>
                  <div>{this.str.privacySection1List1Item2}</div>
                </li>
                <li>
                  <div>{this.str.privacySection1List1Item3}</div>
                </li>
              </ol>
              <p>{this.str.privacySection1Paragraph2}</p>
              <p>{this.str.privacySection1Paragraph3}</p>
              <SectionTitle text={this.str.privacySection2Heading} />
              <p>{this.str.privacySection2Paragraph1}</p>
              <p>{this.str.privacySection2Paragraph2}</p>
              <SectionTitle text={this.str.privacySection3Heading} />
              <p>{this.str.privacySection3Paragraph1}</p>
              <p>{this.str.privacySection3Paragraph2}</p>
              <p>{this.str.privacySection3Paragraph3}</p>
              <SectionTitle text={this.str.privacySection4Heading} />
              <p>{this.str.privacySection4Paragraph1}</p>
              <SectionTitle text={this.str.privacySection5Heading} />
              <p>{this.str.privacySection5Paragraph1}</p>
              <p>{this.str.privacySection5Paragraph2}</p>
              <p>{this.str.privacySection5Paragraph3}</p>
              <SectionTitle text={this.str.privacySection6Heading} />
              <p>{this.str.privacySection6Paragraph1}</p>
              <SectionTitle text={this.str.privacySection7Heading} />
              <p>{this.str.privacySection7Paragraph1}</p>
              <p>{this.str.privacySection7Paragraph2}</p>
              <p>{this.str.privacySection7Paragraph3}</p>
              <SectionTitle text={this.str.privacySection8Heading} />
              <p>{this.str.privacySection8Paragraph1}</p>
              <ol>
                <li>
                  <div>{this.str.privacySection8List1Item1}</div>
                </li>
                <li>
                  <div>{this.str.privacySection8List1Item2}</div>
                </li>
              </ol>
              <p>{this.str.privacySection8Paragraph2}</p>
              <ol>
                <li>
                  <div>{this.str.privacySection8List2Item1}</div>
                </li>
                <li>
                  <div>{this.str.privacySection8List2Item2}</div>
                </li>
                <li>
                  <div>{this.str.privacySection8List2Item3}</div>
                </li>
                <li>
                  <div>{this.str.privacySection8List2Item4}</div>
                </li>
                <li>
                  <div>{this.str.privacySection8List2Item5}</div>
                </li>
                <li>
                  <div>{this.str.privacySection8List2Item6}</div>
                </li>
                <li>
                  <div>{this.str.privacySection8List2Item7}</div>
                </li>
                <li>
                  <div>{this.str.privacySection8List2Item8}</div>
                </li>
                <li>
                  <div>{this.str.privacySection8List2Item9}</div>
                </li>
              </ol>
              <SectionTitle text={this.str.privacySection9Heading} />
              <p>{this.str.privacySection9Paragraph1}</p>
              <SectionTitle text={this.str.privacySection10Heading} />
              <p>{this.str.privacySection10Paragraph1}</p>
              <p>{this.str.privacySection10Paragraph2}</p>
              <SectionTitle text={this.str.privacySection11Heading} />
              <p>{this.str.privacySection11Paragraph1}</p>
              <SectionTitle text={this.str.privacySection12Heading} />
              <p>{this.str.privacySection12Paragraph1}</p>
              <SectionTitle text={this.str.privacySection13Heading} />
              <p>{this.str.privacySection13Paragraph1}</p>
              <p>{this.str.privacySection13Paragraph2}</p>
              <p>{this.str.privacySection13Paragraph3}</p>
              <p>{this.str.privacySection13Paragraph4}</p>
              <p>{this.str.privacySection13Paragraph5}</p>
            </Column>
          </Grid>
        </StyledPage>
      </>
    );
  }
}
