import { ILocStrings } from "./interface";

const strings: ILocStrings = {
  lang: "id",

  //#region General

  unknownError: "Terjadi kesalahan yang tidak diketahui",
  commonPrivacyPolicy: "Privasi",
  commonTermsAndConditions: "Persyaratan",

  //#endregion General

  //#region Nav

  navNonprofits: "Jelajahi nonprofit",
  navWho: "Siapakah kami",
  navContact: "Hubungi kami",
  navDonate: "Donasi",
  navLangSwitch: "Switch to English",

  //#endregion Nav

  //#region /index

  homeHeroHeading: "Menyediakan informasi terpercaya dan akses yang Anda butuhkan untuk membantu Indonesia",
  homeHeroImgAlt: "Anak-anak sedang duduk di lantai dan tertawa",

  aboutWhoHeading: "Siapakah kami",
  aboutWhoContent1:
    "Kami adalah organisasi nirlaba 501(c)(3) independen yang menghubungkan para donor dari Amerika Serikat dengan organisasi-organisasi nonprofit terpercaya di Indonesia. Tindakan kami dipandu oleh keyakinan bahwa setiap organisasi amal di Indonesia patut memiliki kesempatan yang sama untuk mendapatkan akses ke donor dari Amerika Serikat. Kami bekerja sama dengan organisasi-organisasi nonprofit Indonesia untuk membantu mereka menjadi lebih dikenal dan transparan sehingga para donatur dapat melihat dampak dari kontribusi yang telah mereka berikan dan menjadi semakin yakin untuk meyumbang.",
  aboutWhoContent2:
    "Kami menangani segala bagian yang sulit dalam pemberian sumbangan internasional agar Anda dapat dengan mudah mendukung misi yang Anda pedulikan.",
  aboutVisionHeading: "Visi kami",
  aboutVisionContent: "Ekosistem amal Indonesia yang transparan dan efektif.",
  aboutMissionHeading: "Misi kami",
  aboutMissionContent:
    "Untuk membuat dampak positif di Indonesia dengan membangun jaringan terpercaya organisasi nonprofit Indonesia dan menghubungkan mereka dengan para donor dari Amerika Serikat.",

  projectsHeading: "Apa yang kami kerjakan",
  projectsSubheading: "Berikut adalah proyek-proyek yang sedang kami kembangkan.",
  projectsGivingTitle: "Giving platform",
  projectsGivingDescription: "Platform online untuk memberikan sumbangan kepada organisasi nonprofit di Indonesia.",
  projectsGivingImgAlt: "Seorang petani Indonesia sedang bekerja di sawah",
  projectsCharityTitle: "Charity assessment",
  projectsCharityDescription: "Direktori dan analisis organisasi nonprofit Indonesia.",
  projectsCharityImgAlt: "Seorang guru sedang mengajar sekelompok siswa-siswi Indonesia di ruang kelas.",
  projectsCtaDonate: "Beri sumbangan",
  projectsCtaExplore: "Telusuri",

  contactHeading: "Hubungi kami",
  contactParagraph1:
    "Apakah Anda punya pertanyaan? Ingin bekerjasama dengan kami? Atau bahkan jika Anda hanya ingin menyapa, kami ingin mendengar dari Anda. Silakan menghubungi kami di",
  contactParagraph2:
    "Ini hanyalah awal dari misi kami. Apakah Anda ingin mendapatkan kabar perkembangan kami? Kirimkan email Anda melalui formulir ini. Kami tidak akan mengirimkan spam, hanya berita penting tentang kemajuan kami.",

  subFirstNameText: "Nama depan",
  subLastNameText: "Nama belakang",
  subEmailText: "Email",
  subSubmitSubscribe: "Subscribe",
  subSubmitSubmitting: "Sedang diproses...",
  subSubmitSuccess: "Berhasil",
  subSubmitFailed: "Coba lagi",
  subStatusSuccess: "Terima kasih!",
  subStatusFailed: "Gagal untuk mengirimkan email. Harap coba lagi.",

  footerProjects: "Proyek kami",
  footerGivingPlatform: "Giving platform",
  footerCharityAssessment: "Charity assessment",
  footerContact: "Hubungi kami",
  footerInstagramAlt: "Akun Instagram Give to Indonesia",
  footerTwitterAlt: "Akun Twitter Give to Indonesia",
  footerFacebookAlt: "Akun Facebook Give to Indonesia",
  footerLinkedinAlt: "Akun LinkedIn Give to Indonesia",
  footerCopyright:
    "©2024 Give to Indonesia Foundation. Hak cipta dilindungi undang-undang. Give to Indonesia Foundation adalah organisasi nirlaba terdaftar. EIN: 85-1717201.",

  //#endregion /index

  //#region /donate

  donationHeroHeading: "Dukung pelayanan kami",
  donationTitle: "Donasi untuk Give to Indonesia",
  donationFormLoadingText: "Harap tunggu",
  donationFormProcessingText: "Memproses",
  donationFormFirstNameLabel: "Nama Depan",
  donationFormFirstNameErrorMessage: "Harap masukkan nama depan yang valid.",
  donationFormLastNameLabel: "Nama Belakang",
  donationFormLastNameErrorMessage: "Harap masukkan nama belakang yang valid.",
  donationFormCompanyLabel: "Perusahaan",
  donationFormCompanyErrorMessage: "Harap masukkan nama perusahaan yang valid.",
  donationFormEmailLabel: "Email",
  donationFormEmailErrorMessage: "Harap masukkan alamat email yang valid.",
  donationFormAnonymousLabel: "Saya ingin agar sumbangan ini tetap anonim.",
  donationFormCauseRequestLabel: "Saya ingin memilih program tujuan sumbangan ini.",
  donationFormAmountLabel: "Jumlah",
  donationFormOtherAmountLabel: "Lainnya",
  donationFormOtherAmountErrorMessage: "Harap masukkan jumlah donasi dalam bilangan bulat dan lebih besar dari nol.",
  donationFormDonateButton: "Sumbang",
  donationFormSecurityMessage: "Transaksi sumbangan Anda aman dan terjamin.",
  donationFormRequiredFieldDescription: "Kolom yang harus diisi.",
  donationFormLegalNotes:
    "Give to Indonesia Foundation akan melakukan yang terbaik untuk menggunakan kontribusi Anda untuk tujuan, jika ada, yang Anda tentukan. Ini tidak sering terjadi, tetapi jika Give to Indonesia Foundation tidak dapat mendanai tujuan/amal yang Anda rekomendasikan, Give to Indonesia Foundation akan menggunakan kontribusi Anda untuk tujuan amal serupa dan, bila memungkinkan, akan berkonsultasi dengan Anda tentang hal tersebut.",
  donationFormInvalidInputFieldsErrorMessage: "Harap perbaiki input yang tidak valid.",
  donationFormPaymentFailedErrorMessage: "Gagal memproses pembayaran.",
  donationFormInternalServerError: "Terjadi kesalahan dari server.",

  //#endregion /donate

  //#region /donation/{transactionId}

  donationReceiptHeading: "Terima kasih telah mendukung Give to Indonesia",
  donationReceiptTitleText: "Sumbangan Anda sebesar",
  donationReceiptFailedStatus: "gagal diproses",
  donationReceiptCompletedStatus: "telah selesai diproses",
  donationReceiptSubmittedStatus: "telah dikirimkan",
  donationReceiptMessageThankYou: "Terima kasih,",
  donationReceiptMessageAmount1: ", untuk sumbangan sebesar",
  donationReceiptMessageAmount2: "yang telah Anda berikan kepada Give to Indonesia Foundation",
  donationReceiptMessageTransactionId: "Nomor transaksi Anda adalah",
  donationReceiptMessageEmail: "Bukti pembayaran telah dikirimkan ke",
  donationReceiptMessageKeepForRecords: "Harap disimpan sebagai catatan Anda.",
  donationReceiptNotFoundErrorMessage: "Donasi tidak ditemukan",
  donationReceiptPrintButton: "Cetak",
  donationReceiptPrintedVersionTitle: "Donasi Anda untuk Give to Indonesia Foundation",
  donationReceiptPrintedVersionTransactionIdLabel: "Nomor Transaksi",
  donationReceiptPrintedVersionDonorInformationHeading: "Informasi Donor",
  donationReceiptPrintedVersionNameLabel: "Nama",
  donationReceiptPrintedVersionCompanyLabel: "Perusahaan",
  donationReceiptPrintedVersionTransactionInformationHeading: "Informasi Transaksi",
  donationReceiptPrintedVersionAmountLabel: "Jumlah",
  donationReceiptPrintedVersionTransactionDateLabel: "Tanggal Transaksi",
  donationReceiptPrintedVersionPaymentInformationHeading: "Informasi Pembayaran",
  donationReceiptPrintedVersionCreditCardTypeLabel: "Tipe Kartu",
  donationReceiptPrintedVersionCreditCardNumberLabel: "Nomor Kartu Kredit",
  donationReceiptPrintedVersionAdditionalInfo:
    "dalam nilai moneter yang dibayar secara online. Jumlah penuh dari sumbangan Anda dapat dipotong pajak sejauh diizinkan oleh undang-undang karena Give to Indonesia Foundation tidak memberikan barang atau jasa apa pun kepada Anda sebagai imbalan atas kontribusi Anda. Pengakuan ini diberikan sesuai dengan Section 170(f)(8) dari Internal Revenue Code. Give to Indonesia Foundation adalah organisasi nirlaba terdaftar 501(c)(3). EIN: 85-1717201. Harap simpan dokumen ini untuk catatan pajak Anda.",
  donationReceiptPrintedVersionPaymentTypeLabel: "Tipe Pembayaran",
  donationReceiptPrintedVersionVenmoIDLabel: "ID Venmo",

  //#endregion /donation/{transactionId}

  //#region /nonprofits

  nonprofitsHeroHeading: "Temukan dan dukung yayasan yang bekerja dalam bidang yang berarti bagi Anda",
  nonprofitsHeroImgAlt: "Seorang guru sedang mengajar sekelompok siswa-siswi Indonesia di ruang kelas.",
  nonprofitsHeading: "Lembaga-lembaga nonprofit terpercaya di Indonesia",
  nonprofitsDescription:
    "Lembaga-lembaga nonprofit Indonesia yang terdaftar di bawah ini telah diperiksa oleh Give to Indonesia. Lihat profil mereka, baca cerita mereka, dan dukung mereka dalam membuat dampak positif dalam komunitas-komunitas lokal di seluruh Indonesia.",
  nonprofitsTileDoctorshareTitle: "doctorSHARE",
  nonprofitsTileDoctorshareDescription: "Membawa akses kesehatan kepada masyarakat Indonesia di daerah terpencil.",
  nonprofitsTileDoctorshareImgAlt:
    "Salah satu rumah sakit apung milik doctorSHARE sedang berlabuh di Kepulauan Tanimbar, Indonesia.",
  nonprofitsTileDoctorshareTag: "Kesehatan, Bantuan kemanusiaan",

  //#endregion /nonprofits

  //#region NonprofitProfile

  nonprofitProfileInPageNavItemSummary: "Ringkasan",
  nonprofitProfileInPageNavItemImpact: "Dampak",
  nonprofitProfileInPageNavItemPrograms: "Program",
  nonprofitProfileInPageNavItemFinancials: "Keuangan",
  nonprofitProfileSummaryReadMoreLink: "Pelajari lebih lanjut tentang apa yang kami lakukan",
  nonprofitProfileVisionSectionTitle: "Visi",
  nonprofitProfileMissionSectionTitle: "Misi",
  nonprofitProfileInfoAka: "Nama lain",
  nonprofitProfileInfoWebsite: "Situs web",
  nonprofitProfileInfoAddress: "Alamat",
  nonprofitProfileInfoFounded: "Didirikan",
  nonprofitProfileInfoIssueAreas: "Bidang pelayanan",
  nonprofitProfileProgramsSectionTitle: "Program",
  nonprofitProfileRegionsMapSectionTitle: "Wilayah pelayanan",
  nonprofitProfileRegionsMapAlt: "Peta wilayah Indonesia yang dilayani oleh",
  nonprofitProfileImpactTitle: "Dampak",
  nonprofitProfileImpactDescription: "Ukuran kemajuan dan informasi hasil kerja yang dilaporkan oleh",
  nonprofitProfileImpactMeasureDescription: "Total menurut tahun",
  nonprofitProfileFinancialsTitle: "Keuangan",
  nonprofitProfileFinancialsDescription: "Laporan keuangan yang dilaporkan oleh",
  nonprofitProfileFinancialsCurrencyNote: "dalam Rupiah",
  nonprofitProfileFinancialsFiscalYear: "Tahun fiskal",
  nonprofitProfileFinancialsRevenueExpenses: "Pendapatan dan Pengeluaran",
  nonprofitProfileFinancialsBalanceSheet: "Neraca Keuangan",
  nonprofitProfileFinancialsRevenue: "Pendapatan",
  nonprofitProfileFinancialsExpenses: "Pengeluaran",
  nonprofitProfileFinancialsAssets: "Aset",
  nonprofitProfileFinancialsTotalRevenue: "Total pendapatan",
  nonprofitProfileFinancialsTotalExpenses: "Total pengeluaran",
  nonprofitProfileFinancialsTotalAssets: "Total aset",
  nonprofitProfileFinancialsTotalLiabilities: "Total liabilitas",
  nonprofitProfileFinancialsNetAssets: "Aset neto",
  nonprofitProfileDonateTitlePart1: "Bantu",
  nonprofitProfileDonateTitlePart2: "melalui Give to Indonesia.",
  nonprofitProfileDonate: "Sumbang sekarang",

  //#endregion NonprofitProfile

  //#region Nonprofit issue areas

  issueAreaHealthcare: "Kesehatan",
  issuAreaHumanitarianAid: "Bantuan kemanusiaan",
  issueAreaEducation: "Pendidikan",
  issueAreaLivelihood: "Mata pencaharian",
  issueAreaCivilSociety: "Masyarakat Sipil",
  nonprofitsTileYadasTitle: "Yayasan Dairi Saroha",
  nonprofitsTileYadasDescription: "Meningkatkan kemampuan sumber daya masyarakat Dairi.",
  nonprofitsTileYadasImgAlt: "Pelajar dari Kabupaten Dairi.",
  nonprofitsTileYadasTag: "Pendidikan, Mata pencaharian, masyarakat sipil",

  //#endregion Nonprofit issue areas

  //#region /nonprofits/doctorshare

  doctorshareHeroImgAlt:
    "Salah satu rumah sakit apung milik doctorSHARE sedang berlabuh di Kepulauan Tanimbar, Indonesia.",
  doctorshareSummaryTitle: "Membawa akses kesehatan kepada masyarakat Indonesia di daerah terpencil.",
  doctorshareSummary:
    "Pendiri doctorSHARE, dr. Lie Dharmawan sedang menyelesaikan operasi terakhir pada pelayanan medis di Pulau Kei, Maluku, pada tahun 2009, ketika datang anak berusia sembilan tahun bersama ibunya. Ibu Susanti yang kebingungan karena kondisi kesehatan anaknya memohon pada dr. Lie untuk menyelamatkan nyawa putrinya. Sang ibu telah membawa Susanti berlayar melintasi lautan selama tiga hari untuk mencari bantuan. Buruknya kondisi layanan kesehatan di daerahnya membuat sang ibu berani membawa anaknya dengan sampan.<br /><br />Susanti tidak sadarkan diri di pelukan ibunya karena kondisi usus terjepit dan berisiko mengalami pendarahan dalam. Ibunda Susanti dalam keadaan putus asa dan panik mencari bantuan untuk kesembuhan anaknya.<br /><br />Kasus Susanti merupakan satu dari banyak persoalan kesehatan yang dihadapi masyarakat Indonesia di daerah terpencil. doctorSHARE hadir dengan tujuan untuk menjembatani ketimpangan dan menyediakan layanan kesehatan bagi masyarakat yang tidak memiliki akses bantuan kesehatan yang layak.<br /><br />Menjadi negara terpadat ke-4 di dunia dan negara kepulauan terbesar, Indonesia memiliki tantangan mengenai akses kesehatan. Banyak pulau-pulau di Indonesia yang berada di pinggiran, terpencil, dan tidak memiliki akses kesehatan. Kondisi keuangan dan infrastruktur menjadi masalah utama bagi masyarakat untuk mendapatkan layanan kesehatan yang layak.<br /><br />doctorSHARE membawa akses layanan kesehatan ke daerah terpencil melalui rumah sakit apung dan Dokter Terbang. Tujuan kami adalah membantu menyelamatkan setiap nyawa dan mengurangi penderitaan orang lain sehingga dapat membangun kembali masa depan mereka.",
  doctorShareVision:
    "Memberdayakan masyarakat untuk keluar dari penderitaan yang dialami dengan tenaganya sendiri secara holistik.",
  doctorShareMission:
    "Memperbaiki derajat kesehatan Indonesia, terutama di Indonesia Timur melalui penyediaan akses pelayanan kesehatan holistik dan program inovatif berkesinambungan berbasis semangat kerelawanan.",
  doctorShareLogoAlt: "Logo doctorSHARE.",
  doctorShareProgram1CardTitle: "Tanggap Darurat di Nusa Tenggara Timur (NTT)",
  doctorShareProgram1CardDescription:
    "Sebagai rumah sakit apung swasta pertama dan terkecil di dunia, RSA dr. Lie Dharmawan dilengkapi dengan EKG, USG, laboratorium, ruang operasi, ruang resusitasi, dan ruang pemeriksaan pasien, setaraf dengan rumah sakit tipe D.",
  doctorShareProgram1CardImgAlt: "RSA dr. Lie Dharmawan, salah satu rumah sakit apung milik doctorSHARE.",
  doctorShareProgram2CardTitle: "Rumah Sakit Apung #2",
  doctorShareProgram2CardDescription:
    "Rumah sakit apung kedua milik doctorSHARE, RSA Nusa Waluya I, merupakan kerjasama antara doctorSHARE dan Yayasan Eka Dharma. Dilengkapi dengan EKG, USG, laboratorium, dua ruang operasi, ruang resusitasi, ruang konsultasi, klinik gigi, dan 10 tempat tidur rawat inap, setaraf dengan rumah sakit tipe D.",
  doctorShareProgram2CardImgAlt: "RSA Nusa Waluya I, salah satu rumah sakit apung milik doctorSHARE.",
  doctorShareProgram3CardTitle: "Rumah Sakit Apung #3",
  doctorShareProgram3CardDescription:
    "RSA Nusa Waluya II adalah rumah sakit tongkang pertama di dunia. RS ini memiliki 61 kamar, termasuk ruang operasi, ICU, tempat tidur rawat inap, dan fasilitas kesehatan sekunder lainnya, termasuk radiologi, laboratorium, perawatan gigi & mata, dan farmasi, setaraf dengan rumah sakit darat Tipe C.",
  doctorShareProgram3CardImgAlt: "RSA Nusa Waluya II, salah satu rumah sakit apung milik doctorSHARE.",
  doctorShareProgram4CardTitle: "Dokter Terbang",
  doctorShareProgram4CardDescription:
    "Untuk memenuhi kebutuhan orang-orang yang tinggal di daerah pegunungan terpencil yang hanya dapat diakses melalui udara atau dengan berjalan kaki, doctorSHARE bermitra dengan beberapa pilot pesawat kecil untuk menjangkau komunitas tersebut.",
  doctorShareProgram4CardImgAlt:
    "Sekelompok orang Indonesia sedang menunggu kedatangan helikopter di daerah yang dikelilingi pegunungan.",
  doctorShareProgram5CardTitle: "Panti Rawat Gizi",
  doctorShareProgram5CardDescription:
    "Menanggapi masalah kurang gizi akut di Maluku, doctorSHARE memprakarsai program Panti Rawat Gizi di Pulau Kei dengan mengaktifkan kembali klinik pada 2018. Program ini bertujuan untuk mengembalikan status gizi anak di bawah umur lima tahun dengan gizi buruk dan wanita penderita kekurangan energi kronis melalui penyediaan makanan bergizi serta perawatan medis.",
  doctorShareProgram5CardImgAlt: "Seorang pria sedang diberi makan dengan obat-obatan oleh seorang profesional medis.",
  doctorShareProgram6CardTitle: "Program Tuberkulosis",
  doctorShareProgram6CardDescription:
    "Program percontohan Tuberkulosis (TB) di Papua adalah program yang bertujuan untuk mengurangi prevalensi TB melalui kesadaran, deteksi dini, perawatan pengidap TB, dan pemberdayaan masyarakat untuk menjadi Pendamping Minum Obat (PMO). Klinik ini juga menyediakan layanan medis dasar untuk masyarakat di daerah setempat.",
  doctorShareProgram6CardImgAlt: "Seorang balita sedang digendong oleh ibunya.",
  doctorShareImpactMeasure1Title: "Operasi besar",
  doctorShareImpactMeasure2Title: "Operasi kecil",
  doctorShareImpactMeasure3Title: "Bantuan kelahiran",
  doctorShareImpactMeasure4Title: "Perawatan dan konsultasi rawat jalan",
  doctorShareImpactMeasure5Title: "USG dan antenatal",
  doctorShareImpactMeasure6Title: "Perawatan gigi",
  doctorShareImpactMeasure7Title: "Pasien IGD",
  doctorShareImpactMeasure8Title: "Pasien laboratorium",
  doctorShareImpactMeasure9Title: "Resep apotek",
  doctorShareImpactMeasure10Title: "Pendidikan kesehatan",
  doctorShareImpactMeasure11Title: "Relawan",
  doctorShareFinancialsRevenueRow1Name: "Sumbangan",
  doctorShareFinancialsRevenueRow2Name: "Pendapatan lain-lain",
  doctorShareFinancialsExpensesRow1Name: "Program",
  doctorShareFinancialsExpensesRow2Name: "Umum dan administrasi",
  doctorShareFinancialsExpensesRow3Name: "Kapal",
  doctorShareFinancialsExpensesRow4Name: "Beban lain-lain",

  //#endregion /nonprofits/doctorshare

  //#region /nonprofits/yayasandairisaroha

  yadasHeroImgAlt: "Pelajar dari Kabupaten Dairi.",
  yadasSummaryTitle: "Meningkatkan kemampuan sumber daya masyarakat Dairi",
  yadasSummary:
    "Yayasan Dairi Saroha (Yadas) merupakan lembaga yang dibentuk oleh perantau dari daerah Kabupaten Dairi, Sumatera Utara, Indonesia. Yayasan ini bertujuan untuk membangun Kabupaten Dairi dengan menjalin mitra dengan pemerintah Kabupaten Dairi. Kehadiran Yadas diharapkan akan dapat meningkatkan sumber daya manusia melalui kegiatan seminar, penyediaan ahli pertanian, dan peningkatan pendidikan melalui pemberian beasiswa dan penyediaan sarana pendukung. Disamping itu Yadas juga diharapkan dapat membantu pembangunan insfrastruktur seperti sekolah, jalan, jembatan dan sarana lainnya.",
  yadasVision: "Menjadi mitra pemerintah Kabupaten Dairi untuk mewujudkan masyarakat kabupaten Dairi yang Sejahtera.",
  yadasMission:
    "Menghimpun masyarakat perantau yang berasal dari Kabupaten Dairi untuk peduli dan bekerjasama dengan Pemerintah Kabupaten Dairi untuk meningkatkan kesejahteraan masyarakat Dairi.",
  yadasLogoAlt: "Yayasan Dairi Saroha logo.",
  yadasProgram1CardTitle: "Beasiswa perguruan tinggi",
  yadasProgram1CardDescription:
    "Memberikan beasiswa kepada pelajar berprestasi dari Kabupaten Dairi yang kurang mampu secara ekonomi untuk belajar di perguruan tinggi nasional.",
  yadasProgram1CardImgAlt: "Penerima beasiswa kuliah dan keluarganya.",
  yadasProgram2CardTitle: "Seminar bagi pelajar SMA",
  yadasProgram2CardDescription: "Seminar motivasi untuk murid SMA Dairi yang dilakukan setiap tahun.",
  yadasProgram2CardImgAlt: "Pelajar SMA di ruang kelas.",
  yadasProgram3CardTitle: "Pengembangan produk kopi Arabika Sidikalang",
  yadasProgram3CardDescription:
    "Membantu mengembangkan industri kopi Sidikalang melalui pembentukan Kelompok Tani dan pengadaan seminar serta penyuluhan bagi petani-petani kopi lokal.",
  yadasProgram3CardImgAlt: "Anggota-anggota sebuah Kelompok Tani.",
  yadasProgram4CardTitle: "Sumbangan bagi sekolah Dairi",
  yadasProgram4CardDescription:
    "Memberikan sumbangan bagi sekolah-sekolah di Kabupaten Dairi baik berupa peralatan maupun program bimbingan belajar.",
  yadasProgram4CardImgAlt: "Guru menerima sumbangan buku.",
  yadasProgram5CardTitle: "Kursus bahasa Inggris bagi pelajar SD dan SMP",
  yadasProgram5CardDescription:
    "Memberikan kursus Bahasa Inggris kepada murid SD dan SMP di berbagai desa di Kabupaten Dairi.",
  yadasProgram5CardImgAlt: "Anak-anak dalam sebuah kursus Bahasa Inggris.",
  yadasImpactMeasure1Title: "Penerima beasiswa perguruan tinggi nasional",
  yadasImpactMeasure2Title: "Seminar bagi pelajar SMA",
  yadasImpactMeasure3Title: "Seminar dan penyuluhan bagi petani kopi",
  yadasImpactMeasure4Title: "Sekolah penerima sumbangan peralatan",
  yadasImpactMeasure5Title: "Bimbingan belajar di sekolah",
  yadasImpactMeasure6Title: "Kursus bahasa Inggris bagi pelajar SD dan SMP",
  yadasFinancialsRevenueRow1Name: "Sumbangan",
  yadasFinancialsRevenueRow2Name: "Bunga",
  yadasFinancialsExpensesRow1Name: "Beasiswa",
  yadasFinancialsExpensesRow2Name: "Bantuan sekretariat pemerintah lokal",
  yadasFinancialsExpensesRow3Name: "Honor pengajar bahasa Inggris",
  yadasFinancialsExpensesRow4Name: "Administrasi dan umum",

  //#endregion /nonprofits/yayasandairisaroha

  //#region /amazonsmile

  smileHero: "Dukung kami saat Anda berbelanja di Amazon",
  smileHeroImgAlt: "Petani-petani Indonesia sedang bersepeda di samping sawah",
  smileHeading: "Cara berbelanja di AmazonSmile",
  smileSubheading:
    "AmazonSmile menyediakan cara sederhana bagi Anda untuk mendukung organisasi kami setiap kali Anda berbelanja di Amazon, tanpa biaya tambahan. Untuk mulai memberikan kontribusi kepada kami menggunakan akun Amazon Anda, ikuti langkah-langkah berikut.",
  smileDesktopHeading: "Menyiapkan AmazonSmile di desktop",
  smileDesktopItem1:
    "Buka <a href='https://smile.amazon.com' target='_blank' rel='noreferrer noopener'>smile.amazon.com</a> dan masuk menggunakan kredensial Amazon.com Anda.",
  smileDesktopItem2: "Saat diminta untuk memilih organisasi nonprofit, cari 'Give to Indonesia Foundation'.",
  smileDesktopItem2ImgAlt: "Cari Give to Indonesia Foundation",
  smileDesktopItem3: "Pilih 'Give to Indonesia Foundation' dari hasil pencarian.",
  smileDesktopItem3ImgAlt: "Pilih 'Give to Indonesia Foundation' dari hasil pencarian.",
  smileDesktopItem4:
    "Setiap kali Anda berbelanja di Amazon, pastikan untuk mengunjungi <a href='https://smile.amazon.com' target='_blank' rel='noreferrer noopener'> smile.amazon.com </a>, dan bukan <a href='https://www.amazon.com' target='_blank' rel='noreferrer noopener'> www.amazon.com </a>. Jika Anda mengunjungi halaman yang benar, Anda akan melihat logo AmazonSmile di pojok kiri atas, dan teks 'Give to Indonesia Foundation' di bawah kotak pencarian.",
  smileDesktopItem4ImgAlt: "Logo AmazonSmile and teks Give to Indonesia Foundation",
  smileDesktopItem5: "Selamat berbelanja dan terima kasih atas dukungan Anda.",
  smileMobileHeading: "Menyiapkan AmazonSmile di aplikasi ponsel",
  smileMobileItem1:
    "Buka aplikasi Amazon Shopping di ponsel Anda dan masuk menggunakan kredensial Amazon.com Anda. Jika Anda tidak memiliki aplikasi Amazon Shopping di ponsel Anda, Anda dapat mengunduhnya dari <a href='https://play.google.com/store/apps/details?id=com.amazon.mShop.android.shopping' target='_blank' rel='noreferrer noopener'>Google Play Store</a> atau <a href='https://apps.apple.com/us/app/amazon-shopping/id297606951' target='_blank' rel='noreferrer noopener'>Apple App Store</a>.",
  smileMobileItem2: "Buka menu di kiri, dan buka Settings > AmazonSmile.",
  smileMobileItem2ImgAlt: "Menu aplikasi AmazonSmile",
  smileMobileItem3:
    "Di kolom pencarian amal, masukkan 'Give to Indonesia Foundation' dan pilih Cari. Kemudian pilih 'Give to Indonesia Foundation' dari hasil pencarian.",
  smileMobileItem3ImgAlt: "Tampilan pencarian di aplikasi ponsel",
  smileMobileItem4:
    "Apabila semua langkah pengaturan berhasil, Anda akan melihat tampilan konfirmasi bahwa Anda akan memberikan kontribusi kepada 'Give to Indonesia Foundation'. Pastikan juga fitur AmazonSmile telah dihidupkan.",
  smileMobileItem4ImgAlt: "Tampilan konfirmasi AmazonSmile",
  smileMobileItem5:
    "Anda sekarang dapat berbelanja seperti biasa menggunakan aplikasi Amazon dan Amazon akan menyumbangkan sebagian dari jumlah pengeluaran Anda kepada 'Give to Indonesia Foundation'. Selamat berbelanja dan terima kasih atas dukungan Anda.",

  //#endregion /amazonsmile

  //#region /privacy

  privacyHero: "Privacy Policy",
  privacyHeroImgAlt: "Indonesian farmers cycling next to paddy fields in the morning",
  privacyIntroHeading: "Protecting your private information is our priority",
  privacyIntro:
    "This Statement of Privacy applies to https://givetoindonesia.org and Give to Indonesia Foundation, and governs data collection and usage. For the purposes of this Privacy Policy, unless otherwise noted, all references to Give to Indonesia Foundation include https://givetoindonesia.org and GTIF. The GTIF website is a nonprofit donation and information portal site. By using the GTIF website, you consent to the data practices described in this statement.",
  privacySection1Heading: "Collection of your personal information",
  privacySection1Paragraph1:
    "In order to better provide you with products and services offered, GTIF may collect personally identifiable information, such as your:",
  privacySection1List1Item1: "First and Last Name",
  privacySection1List1Item2: "E-mail Address",
  privacySection1List1Item3: "Employer",
  privacySection1Paragraph2:
    "If you purchase GTIF's products and services, we collect billing and credit card information. This information is used to complete the purchase transaction.",
  privacySection1Paragraph3:
    "We do not collect any personal information about you unless you voluntarily provide it to us. However, you may be required to provide certain personal information to us when you elect to use certain products or services. These may include: (a) registering for an account; (b) entering a sweepstakes or contest sponsored by us or one of our partners; (c) signing up for special offers from selected third parties; (d) sending us an email message; (e) submitting your credit card or other payment information when ordering and purchasing products and services. To wit, we will use your information for, but not limited to, communicating with you in relation to services and/or products you have requested from us. We also may gather additional personal or non-personal information in the future.",
  privacySection2Heading: "Use of your personal information",
  privacySection2Paragraph1:
    "GTIF collects and uses your personal information to operate and deliver the services you have requested. Notwithstanding the foregoing, you consent to GTIF sharing your name and the amount of your donation with any given GTIF third party non-profit recipient to whom you have designated such donation.",
  privacySection2Paragraph2:
    "GTIF may also use your personally identifiable information to inform you of other products or services available from GTIF and its affiliates.",
  privacySection3Heading: "Sharing information with third parties",
  privacySection3Paragraph1: "GTIF does not sell, rent or lease its customer lists to third parties.",
  privacySection3Paragraph2:
    "GTIF may share data with trusted partners to help perform statistical analysis, send you email or postal mail, provide customer support, or arrange for deliveries. All such third parties are prohibited from using your personal information except to provide these services to GTIF, and they are required to maintain the confidentiality of your information.",
  privacySection3Paragraph3:
    "GTIF may disclose your personal information, without notice, if required to do so by law or in the good faith belief that such action is necessary to: (a) conform to the edicts of the law or comply with legal process served on GTIF or the site; (b) protect and defend the rights or property of GTIF; and/or (c) act under exigent circumstances to protect the personal safety of users of GTIF, or the public.",
  privacySection4Heading: "Automatically Collected Information",
  privacySection4Paragraph1:
    "Information about your computer hardware and software may be automatically collected by GTIF. This information can include: your IP address, browser type, domain names, access times and referring website addresses. This information is used for the operation of the service, to maintain quality of the service, and to provide general statistics regarding use of the GTIF website.",
  privacySection5Heading: "Use of cookies",
  privacySection5Paragraph1:
    'The GTIF website may use "cookies to" help you personalize your online experience. A cookie is a text file that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you, and can only be read by a web server in the domain that issued the cookie to you.',
  privacySection5Paragraph2:
    "One of the primary purposes of cookies is to provide a convenience feature to save you time. The purpose of a cookie is to tell the Web server that you have returned to a specific page. For example, if you personalize GTIF pages, or register with GTIF site or services, a cookie helps GTIF to recall your specific information on subsequent visits. This simplifies the process of recording your personal information, such as billing addresses, shipping addresses, and so on. When you return to the same GTIF website, the information you previously provided can be retrieved, so you can easily use the GTIF features that you customized.",
  privacySection5Paragraph3:
    "You have the ability to accept or decline cookies. Most Web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you choose to decline cookies, you may not be able to fully experience the interactive features of the GTIF services or websites you visit.",
  privacySection6Heading: "Links",
  privacySection6Paragraph1:
    "This website contains links to other sites. Please be aware that we are not responsible for the content or privacy practices of such other sites. We encourage our users to be aware when they leave our site and to read the privacy statements of any other site that collects personally identifiable information.",
  privacySection7Heading: "Security of your personal information",
  privacySection7Paragraph1:
    "GTIF secures your personal information from unauthorized access, use, or disclosure using the SSL Protocol.",
  privacySection7Paragraph2:
    "When personal information (such as a credit card number) is transmitted to other websites, it is protected through the use of encryption, such as the Secure Sockets Layer (SSL) protocol.",
  privacySection7Paragraph3:
    "We strive to take appropriate security measures to protect against unauthorized access to or alteration of your personal information. Unfortunately, no data transmission over the Internet or any wireless network can be guaranteed to be 100% secure. As a result, while we strive to protect your personal information, you acknowledge that: (a) there are security and privacy limitations inherent to the Internet which are beyond our control; and (b) security, integrity, and privacy of any and all information and data exchanged between you and us through this Site cannot be guaranteed.",
  privacySection8Heading: "Right to deletion",
  privacySection8Paragraph1:
    "Subject to certain exceptions set out below, on receipt of a verifiable request from you, we will:",
  privacySection8List1Item1: "Delete your personal information from our records; and",
  privacySection8List1Item2: "Direct any service providers to delete your personal information from their records.",
  privacySection8Paragraph2:
    "Please note that we may not be able to comply with requests to delete your personal information if it is necessary to:",
  privacySection8List2Item1:
    "Complete the transaction for which the personal information was collected, fulfill the terms of a written warranty or product recall conducted in accordance with federal law, provide a good or service requested by you, or reasonably anticipated within the context of our ongoing business relationship with you, or otherwise perform a contract between you and us;",
  privacySection8List2Item2:
    "Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity; or prosecute those responsible for that activity;",
  privacySection8List2Item3: "Debug to identify and repair errors that impair existing intended functionality;",
  privacySection8List2Item4:
    "Exercise free speech, ensure the right of another consumer to exercise his or her right of free speech, or exercise another right provided for by law;",
  privacySection8List2Item5: "Comply with the California Electronic Communications Privacy Act;",
  privacySection8List2Item6:
    "Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when our deletion of the information is likely to render impossible or seriously impair the achievement of such research, provided we have obtained your informed consent;",
  privacySection8List2Item7:
    "Enable solely internal uses that are reasonably aligned with your expectations based on your relationship with us;",
  privacySection8List2Item8: "Comply with an existing legal obligation; or",
  privacySection8List2Item9:
    "Otherwise use your personal information, internally, in a lawful manner that is compatible with the context in which you provided the information.",
  privacySection9Heading: "Children under thirteen",
  privacySection9Paragraph1:
    "GTIF does not knowingly collect personally identifiable information from children under the age of thirteen. If you are under the age of thirteen, you must ask your parent or guardian for permission to use this website.",
  privacySection10Heading: "E-mail communications",
  privacySection10Paragraph1:
    "From time to time, GTIF may contact you via email for the purpose of providing announcements, promotional offers, alerts, confirmations, surveys, and/or other general communication.",
  privacySection10Paragraph2:
    "If you would like to stop receiving marketing or promotional communications via email from GTIF, you may opt out of such communications by emailing info@givetoindonesia.org.",
  privacySection11Heading: "External data storage sites",
  privacySection11Paragraph1:
    "We may store your data on servers provided by third party hosting vendors with whom we have contracted.",
  privacySection12Heading: "Changes to this statement",
  privacySection12Paragraph1:
    "GTIF reserves the right to change this Privacy Policy from time to time. We will notify you about significant changes in the way we treat personal information by sending a notice to the primary email address specified in your account, by placing a prominent notice on our website, and/or by updating any privacy information. Your continued use of the website and/or Services available after such modifications will constitute your: (a) acknowledgment of the modified Privacy Policy; and (b) agreement to abide and be bound by that Policy.",
  privacySection13Heading: "Contact information",
  privacySection13Paragraph1:
    "GTIF welcomes your questions or comments regarding this Statement of Privacy. If you believe that GTIF has not adhered to this Statement, please contact GTIF at:",
  privacySection13Paragraph2: "Give to Indonesia Foundation\r\n15127 NE 24th St. Ste 766\r\nRedmond, Washington 98052",
  privacySection13Paragraph3: "Email Address:\r\ninfo@givetoindonesia.org",
  privacySection13Paragraph4: "Telephone number:\r\n+1 989 800 1945",
  privacySection13Paragraph5: "Effective as of June 01, 2021",

  //#endregion /privacy

  //#region /terms

  termsHero: "Terms and Conditions",
  termsHeroImgAlt: "Indonesian farmers cycling next to paddy fields in the morning",
  termsSection1Heading: "Agreement between User and https://givetoindonesia.org",
  termsSection1Paragraph1:
    'Welcome to https://givetoindonesia.org. The https://givetoindonesia.org website (the "Site") is comprised of various web pages operated by Give to Indonesia Foundation ("GTIF"). https://givetoindonesia.org is offered to you conditioned on your acceptance without modification of the terms, conditions, and notices contained herein (the "Terms"). Your use of https://givetoindonesia.org constitutes your agreement to all such Terms. Please read these terms carefully, and keep a copy of them for your reference.',
  termsSection1Paragraph2: "https://givetoindonesia.org is a Non-Profit Site. ",
  termsSection1Paragraph3:
    "This website is intended for United States donors to make donation to Give to Indonesia Foundation and learn about Indonesian nonprofits that partnered with Give to Indonesia Foundation. ",
  termsSection1Paragraph4:
    "Your donation will be made to Indonesia Foundation, a 501(c)(3) charity. Give to Indonesia Foundation covers all fees. Give to Indonesia Foundation will do its best to use your contributions for the purpose, if any, that you specified. This doesn't happen often, but if Give to Indonesia Foundation cannot fund the cause / charity you recommend, it will use your contributions for similar charitable purposes and whenever possible will consult with you on the reassignment. GTIF performs due diligence on the third party non-profit recipients of your donations. However, GTIF disclaims liability for any fraudulent activity or information provided by any such third party non-profit recipient of your donations.",
  termsSection2Heading: "Privacy",
  termsSection2Paragraph1: "Your use of https://givetoindonesia.org is subject to GTIF's",
  termsSection2Paragraph1b: ", which also governs the Site and informs users of our data collection practices.",
  termsSection3Heading: "Electronic communications",
  termsSection3Paragraph1:
    "Visiting https://givetoindonesia.org or sending emails to GTIF constitutes electronic communications. You consent to receive electronic communications and you agree that all agreements, notices, disclosures and other communications that we provide to you electronically, via email and on the Site, satisfy any legal requirement that such communications be in writing.",
  termsSection4Heading: "Children under thirteen",
  termsSection4Paragraph1:
    "GTIF does not knowingly collect, either online or offline, personal information from persons under the age of thirteen. If you are under 18, you may use https://givetoindonesia.org only with permission of a parent or guardian.",
  termsSection5Heading: "Cancellation/refund policy",
  termsSection5Paragraph1:
    "Please email Give to Indonesia Foundation at info@givetoindonesia.org within 48 hours after the donation was made if you would like to get a refund. After the 48 hours window have passed, we would not be able to refund your donation. Please note that GTIF would not be able to refund the processing fees that are charged by the 3rd party payment processing company.",
  termsSection6Heading: "Links to third party sites/third party services",
  termsSection6Paragraph1:
    'https://givetoindonesia.org may contain links to other websites ("Linked Sites"). The Linked Sites are not under the control of GTIF and GTIF is not responsible for the contents of any Linked Site, including without limitation any link contained in a Linked Site, or any changes or updates to a Linked Site. GTIF is providing these links to you only as a convenience, and the inclusion of any link does not imply endorsement by GTIF of the site or any association with its operators.',
  termsSection6Paragraph2:
    "Certain services made available via https://givetoindonesia.org are delivered by third party sites and organizations. By using any product, service or functionality originating from the https://givetoindonesia.org domain, you hereby acknowledge and consent that GTIF may share such information and data with any third party with whom GTIF has a contractual relationship to provide the requested product, service or functionality on behalf of https://givetoindonesia.org users and customers.",
  termsSection7Heading: "No unlawful or prohibited use/intellectual property",
  termsSection7Paragraph1:
    "You are granted a non-exclusive, non-transferable, revocable license to access and use https://givetoindonesia.org strictly in accordance with these terms of use. As a condition of your use of the Site, you warrant to GTIF that you will not use the Site for any purpose that is unlawful or prohibited by these Terms. You may not use the Site in any manner which could damage, disable, overburden, or impair the Site or interfere with any other party's use and enjoyment of the Site. You may not obtain or attempt to obtain any materials or information through any means not intentionally made available or provided for through the Site.",
  termsSection7Paragraph2:
    "All content included as part of the Service, such as text, graphics, logos, images, as well as the compilation thereof, and any software used on the Site, is the property of GTIF or its suppliers and protected by copyright and other laws that protect intellectual property and proprietary rights. You agree to observe and abide by all copyright and other proprietary notices, legends or other restrictions contained in any such content and will not make any changes thereto.",
  termsSection7Paragraph3:
    "You will not modify, publish, transmit, reverse engineer, participate in the transfer or sale, create derivative works, or in any way exploit any of the content, in whole or in part, found on the Site. GTIF content is not for resale. Your use of the Site does not entitle you to make any unauthorized use of any protected content, and in particular you will not delete or alter any proprietary rights or attribution notices in any content. You will use protected content solely for your personal use, and will make no other use of the content without the express written permission of GTIF and the copyright owner. You agree that you do not acquire any ownership rights in any protected content. We do not grant you any licenses, express or implied, to the intellectual property of GTIF or our licensors except as expressly authorized by these Terms.",
  termsSection8Heading: "International users",
  termsSection8Paragraph1:
    "The Service is controlled, operated and administered by GTIF from our offices within the USA. If you access the Service from a location outside the USA, you are responsible for compliance with all local laws. You agree that you will not use the GTIF Content accessed through https://givetoindonesia.org in any country or in any manner prohibited by any applicable laws, restrictions or regulations.",
  termsSection9Heading: "Indemnification",
  termsSection9Paragraph1:
    "You agree to indemnify, defend and hold harmless GTIF, its officers, directors, employees, agents and third parties, for any losses, costs, liabilities and expenses (including reasonable attorney's fees) relating to or arising out of your use of or inability to use the Site or services, any user postings made by you, your violation of any terms of this Agreement or your violation of any rights of a third party, or your violation of any applicable laws, rules or regulations. GTIF reserves the right, at its own cost, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will fully cooperate with GTIF in asserting any available defenses.",
  termsSection10Heading: "Arbitration",
  termsSection10Paragraph1:
    "In the event the parties are not able to resolve any dispute between them arising out of or concerning these Terms and Conditions, or any provisions hereof, whether in contract, tort, or otherwise at law or in equity for damages or any other relief, then such dispute shall be resolved only by final and binding arbitration pursuant to the Federal Arbitration Act, conducted by a single neutral arbitrator and administered by the American Arbitration Association, or a similar arbitration service selected by the parties, in a location mutually agreed upon by the parties. The arbitrator's award shall be final, and judgment may be entered upon it in any court having jurisdiction. In the event that any legal or equitable action, proceeding or arbitration arises out of or concerns these Terms and Conditions, the prevailing party shall be entitled to recover its costs and reasonable attorney's fees. The parties agree to arbitrate all disputes and claims in regards to these Terms and Conditions or any disputes arising as a result of these Terms and Conditions, whether directly or indirectly, including Tort claims that are a result of these Terms and Conditions. The parties agree that the Federal Arbitration Act governs the interpretation and enforcement of this provision. The entire dispute, including the scope and enforceability of this arbitration provision shall be determined by the Arbitrator. This arbitration provision shall survive the termination of these Terms and Conditions.",
  termsSection11Heading: "Class action waiver",
  termsSection11Paragraph1:
    "Any arbitration under these Terms and Conditions will take place on an individual basis; class arbitrations and class/representative/collective actions are not permitted. THE PARTIES AGREE THAT A PARTY MAY BRING CLAIMS AGAINST THE OTHER ONLY IN EACH'S INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PUTATIVE CLASS, COLLECTIVE AND/ OR REPRESENTATIVE PROCEEDING, SUCH AS IN THE FORM OF A PRIVATE ATTORNEY GENERAL ACTION AGAINST THE OTHER. Further, unless both you and GTIF agree otherwise, the arbitrator may not consolidate more than one person's claims, and may not otherwise preside over any form of a representative or class proceeding.",
  termsSection12Heading: "Liability disclaimer",
  termsSection12Paragraph1:
    "THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR AVAILABLE THROUGH THE SITE MAY INCLUDE INACCURACIES OR TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE INFORMATION HEREIN. GIVE TO INDONESIA FOUNDATION AND/OR ITS SUPPLIERS MAY MAKE IMPROVEMENTS AND/OR CHANGES IN THE SITE AT ANY TIME.",
  termsSection12Paragraph2:
    'GIVE TO INDONESIA FOUNDATION AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, AND ACCURACY OF THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS CONTAINED ON THE SITE FOR ANY PURPOSE. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ALL SUCH INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS ARE PROVIDED "AS IS" WITHOUT WARRANTY OR CONDITION OF ANY KIND. GIVE TO INDONESIA FOUNDATION AND/OR ITS SUPPLIERS HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS WITH REGARD TO THIS INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS, INCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.',
  termsSection12Paragraph3:
    "TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL GIVE TO INDONESIA FOUNDATION AND/OR ITS SUPPLIERS BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE, DATA OR PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OR PERFORMANCE OF THE SITE, WITH THE DELAY OR INABILITY TO USE THE SITE OR RELATED SERVICES, THE PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS OBTAINED THROUGH THE SITE, OR OTHERWISE ARISING OUT OF THE USE OF THE SITE, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF GIVE TO INDONESIA FOUNDATION OR ANY OF ITS SUPPLIERS HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE SITE, OR WITH ANY OF THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SITE.",
  termsSection13Heading: "Termination/access restriction",
  termsSection13Paragraph1:
    "GTIF reserves the right, in its sole discretion, to terminate your access to the Site and the related services or any portion thereof at any time, without notice. To the maximum extent permitted by law, this agreement is governed by the laws of the State of Washington and you hereby consent to the exclusive jurisdiction and venue of courts in Washington in all disputes arising out of or relating to the use of the Site. Use of the Site is unauthorized in any jurisdiction that does not give effect to all provisions of these Terms, including, without limitation, this section.",
  termsSection13Paragraph2:
    "You agree that no joint venture, partnership, employment, or agency relationship exists between you and GTIF as a result of this agreement or use of the Site. GTIF's performance of this agreement is subject to existing laws and legal process, and nothing contained in this agreement is in derogation of GTIF's right to comply with governmental, court and law enforcement requests or requirements relating to your use of the Site or information provided to or gathered by GTIF with respect to such use. If any part of this agreement is determined to be invalid or unenforceable pursuant to applicable law including, but not limited to, the warranty disclaimers and liability limitations set forth above, then the invalid or unenforceable provision will be deemed superseded by a valid, enforceable provision that most closely matches the intent of the original provision and the remainder of the agreement shall continue in effect.",
  termsSection13Paragraph3:
    "Unless otherwise specified herein, this agreement constitutes the entire agreement between the user and GTIF with respect to the Site and it supersedes all prior or contemporaneous communications and proposals, whether electronic, oral or written, between the user and GTIF with respect to the Site. A printed version of this agreement and of any notice given in electronic form shall be admissible in judicial or administrative proceedings based upon or relating to this agreement to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form. It is the express wish to the parties that this agreement and all related documents be written in English.",
  termsSection14Heading: "Changes to terms",
  termsSection14Paragraph1:
    "GTIF reserves the right, in its sole discretion, to change the Terms under which https://givetoindonesia.org is offered. The most current version of the Terms will supersede all previous versions. GTIF encourages you to periodically review the Terms to stay informed of our updates.",
  termsSection15Heading: "Contact us",
  termsSection15Paragraph1:
    "GTIF is doing our best to ensure the information shared with and stored on GTIF’s site is accurate but sometimes there are inaccuracies. If you see a data discrepancy, please notify us at the below referenced email address.",
  termsSection15Paragraph2: "GTIF welcomes your questions or comments regarding the Terms:",
  termsSection15Paragraph3: "Give to Indonesia Foundation\r\n15127 NE 24th St Ste 766\r\nRedmond, Washington 98052",
  termsSection15Paragraph4: "Email Address:\r\ninfo@givetoindonesia.org",
  termsSection15Paragraph5: "Telephone number:\r\n+1 989 800 1945",
  termsSection15Paragraph6: "Effective as of June 01, 2021",

  //#endregion /terms
};

export default strings;
