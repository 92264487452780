import React from "react";
import styled from "styled-components";
import ComponentBase from "../component-base";
import { colors, breakpoints } from "../assets/variables/style-variables";
import { Page, Grid, Column } from "./grid";
import { SectionTitle } from "./section-title";
import visionBackgroundImage from "../assets/images/home-parallax.jpg";

const VisionContainer = styled.div`
  position: relative;
  padding: 60px 0 0;
  transform-style: preserve-3d;
  z-index: -1;

  @media (min-width: ${breakpoints.vp2}) {
    padding: 84px 0 48px;
  }
`;

const VisionBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 150%;
  background-image: url(${visionBackgroundImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transform: translateZ(-2px) scale(1.25);
  transform-origin: 0;

  &::after {
    content: " ";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: ${colors.gray.darkExtra};
    opacity: 0.8;
  }
`;

const VisionColumn = styled(Column)`
  position: relative;
  margin: 24px 0 48px;

  @media (min-width: ${breakpoints.vp2}) {
    margin: 48px 0 60px;
  }

  p {
    color: ${colors.gray.light};
  }
`;

export class About extends ComponentBase {
  public render(): JSX.Element {
    return (
      <>
        <Page id="who-we-are" backgroundColor={colors.gray.light}>
          <Grid applyPadding={true}>
            <Column span={[12, 12, 8]}>
              <SectionTitle text={this.str.aboutWhoHeading} />
              <p>{this.str.aboutWhoContent1}</p>
              <p>{this.str.aboutWhoContent2}</p>
            </Column>
          </Grid>
        </Page>
        <VisionContainer>
          <VisionBackground />
          <Page>
            <Grid>
              <VisionColumn span={[12, 12, 8]}>
                <SectionTitle color={colors.gray.light} lineColor={colors.red.light} text={this.str.aboutVisionHeading} />
                <p>{this.str.aboutVisionContent}</p>
              </VisionColumn>
              <VisionColumn span={[12, 12, 8]} row={[2]}>
                <SectionTitle color={colors.gray.light} lineColor={colors.red.light} text={this.str.aboutMissionHeading} />
                <p>{this.str.aboutMissionContent}</p>
              </VisionColumn>
            </Grid>
          </Page>
        </VisionContainer>
      </>
    );
  }
}
