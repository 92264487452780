import React from "react";
import styled from "styled-components";
import ComponentBase from "../component-base";
import { colors, transitions } from "../assets/variables/style-variables";

interface ILogoProps {
  height?: string;
  color?: string;
  accentColor?: string;
}

const SVG = styled.svg<ILogoProps>`
  height: ${(props) => props.height || "42px"};

  [class^="cls-"] {
    transition: color ${transitions.medium};
  }

  .cls-1 {
    fill: ${(props) => props.color || colors.gray.dark};
  }

  .cls-2 {
    fill: ${(props) => props.accentColor || colors.red.base};
  }
`;

export class Logo extends ComponentBase<ILogoProps, unknown> {
  public render(): JSX.Element {
    return (
      <SVG
        height={this.props.height}
        color={this.props.color}
        accentColor={this.props.accentColor}
        id="Layer_1"
        viewBox="0 0 200 80"
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 1"
      >
        <defs />
        <path
          className="cls-1"
          d="M21.28,18h3.07v14a23.43,23.43,0,0,1-3.37.92,28.25,28.25,0,0,1-6.16.57,14.57,14.57,0,0,1-5.69-1.09A12.79,12.79,0,0,1,4.59,29.2a14.63,14.63,0,0,1-3-5.11,20.84,20.84,0,0,1-1.08-7,19.42,19.42,0,0,1,1.17-7A14.91,14.91,0,0,1,4.86,5,13.69,13.69,0,0,1,9.53,1.89,15,15,0,0,1,15.22.81,18.88,18.88,0,0,1,21,1.55,14.58,14.58,0,0,1,24.26,3l-1,2.62a10.16,10.16,0,0,0-3.43-1.47,18.46,18.46,0,0,0-4.7-.57,11.54,11.54,0,0,0-4.43.86A9.76,9.76,0,0,0,7.05,7a12.49,12.49,0,0,0-2.42,4.24,18.08,18.08,0,0,0-.88,5.87,20.39,20.39,0,0,0,.72,5.63A11.94,11.94,0,0,0,6.67,27a9.56,9.56,0,0,0,3.63,2.73,12.49,12.49,0,0,0,5,.95,24.6,24.6,0,0,0,3.86-.25,10.55,10.55,0,0,0,2.1-.47Z"
        />
        <path
          className="cls-1"
          d="M32.48,2.39A2.2,2.2,0,0,1,31.85,4a2.25,2.25,0,0,1-3.08,0,2.2,2.2,0,0,1-.63-1.65A2.2,2.2,0,0,1,28.77.74a2.25,2.25,0,0,1,3.08,0A2.2,2.2,0,0,1,32.48,2.39Zm-.68,30.4H28.86V9.43H31.8Z"
        />
        <path
          className="cls-1"
          d="M44.2,29.62q.94-1.94,1.95-4.44T48.07,20c.62-1.79,1.2-3.6,1.72-5.42s1-3.53,1.34-5.13h2.94c-.55,2.14-1.15,4.25-1.82,6.33s-1.37,4.11-2.11,6.1-1.52,3.9-2.32,5.73-1.59,3.57-2.34,5.2H42.74q-1.18-2.45-2.36-5.2c-.79-1.83-1.56-3.75-2.3-5.73s-1.45-4-2.11-6.1-1.28-4.19-1.82-6.33h3.12q.54,2.4,1.33,5.13c.53,1.82,1.11,3.63,1.72,5.42s1.27,3.52,1.93,5.2S43.56,28.33,44.2,29.62Z"
        />
        <path
          className="cls-1"
          d="M55,21.09a15,15,0,0,1,.88-5.35,11.81,11.81,0,0,1,2.31-3.82,8.94,8.94,0,0,1,3.25-2.28,9.83,9.83,0,0,1,3.77-.75A8.82,8.82,0,0,1,72,11.69q2.55,2.81,2.55,8.63c0,.24,0,.48,0,.72a6.78,6.78,0,0,1-.07.68H58.09a10.33,10.33,0,0,0,2.12,6.59c1.33,1.54,3.43,2.31,6.32,2.31a12.88,12.88,0,0,0,3.8-.45,15.76,15.76,0,0,0,2-.77l.54,2.53a12.07,12.07,0,0,1-2.37.86,15,15,0,0,1-4.09.49,13.7,13.7,0,0,1-5.24-.9,9.38,9.38,0,0,1-3.56-2.53,10,10,0,0,1-2-3.86A18,18,0,0,1,55,21.09Zm16.58-1.85a9.22,9.22,0,0,0-1.72-5.69,5.55,5.55,0,0,0-4.61-2,6.16,6.16,0,0,0-2.82.63,7.3,7.3,0,0,0-2.17,1.7,7.93,7.93,0,0,0-1.44,2.46,9.93,9.93,0,0,0-.61,2.94Z"
        />
        <path className="cls-1" d="M3,44.74H9.21V79.17H3Z" />
        <path
          className="cls-1"
          d="M14.78,54a40.86,40.86,0,0,1,4.52-.94,38.71,38.71,0,0,1,6.16-.45,14.2,14.2,0,0,1,5.32.87A8.3,8.3,0,0,1,34.18,56,9.37,9.37,0,0,1,36,59.72a21,21,0,0,1,.52,4.85v14.6h-6V65.51A19,19,0,0,0,30.2,62a6.38,6.38,0,0,0-.89-2.39,3.57,3.57,0,0,0-1.69-1.34A7.14,7.14,0,0,0,25,57.81a19.75,19.75,0,0,0-2.38.15c-.83.1-1.44.18-1.84.25v21h-6Z"
        />
        <path
          className="cls-1"
          d="M63.42,78.38a37,37,0,0,1-4.5,1,34.62,34.62,0,0,1-5.69.45,15.34,15.34,0,0,1-5.51-.94,11.5,11.5,0,0,1-4.15-2.71,12,12,0,0,1-2.63-4.27A16.2,16.2,0,0,1,40,66.26a18.75,18.75,0,0,1,.77-5.59,12,12,0,0,1,2.26-4.3,10.14,10.14,0,0,1,3.62-2.76,11.93,11.93,0,0,1,4.92-1,11.18,11.18,0,0,1,3.33.45,15.73,15.73,0,0,1,2.49,1V41.61l6-1ZM46.18,66.11a9.4,9.4,0,0,0,1.89,6.23,6.39,6.39,0,0,0,5.21,2.26,21.48,21.48,0,0,0,2.46-.12c.68-.08,1.23-.18,1.67-.27V59.3a9.77,9.77,0,0,0-2.12-1,8.31,8.31,0,0,0-2.8-.47,5.36,5.36,0,0,0-4.8,2.24A10.66,10.66,0,0,0,46.18,66.11Z"
        />
        <path
          className="cls-1"
          d="M92.18,66.16a16.67,16.67,0,0,1-.89,5.61,12.49,12.49,0,0,1-2.53,4.32,11.48,11.48,0,0,1-4,2.79,12.82,12.82,0,0,1-5.1,1,12.58,12.58,0,0,1-5.06-1,11.4,11.4,0,0,1-3.93-2.79,12.7,12.7,0,0,1-2.56-4.32,16.42,16.42,0,0,1-.92-5.61,16.18,16.18,0,0,1,.92-5.59,12.45,12.45,0,0,1,2.59-4.3,11.46,11.46,0,0,1,4-2.76,12.79,12.79,0,0,1,5-1,13,13,0,0,1,5.05,1,11.27,11.27,0,0,1,3.95,2.76,12.87,12.87,0,0,1,2.56,4.3A16.19,16.19,0,0,1,92.18,66.16Zm-6.16,0A10.14,10.14,0,0,0,84.36,60a5.9,5.9,0,0,0-9.29,0,10.14,10.14,0,0,0-1.67,6.14,10.37,10.37,0,0,0,1.67,6.21,5.87,5.87,0,0,0,9.29,0A10.37,10.37,0,0,0,86,66.16Z"
        />
        <path
          className="cls-1"
          d="M96,54a41.36,41.36,0,0,1,4.52-.94,38.8,38.8,0,0,1,6.16-.45,14.2,14.2,0,0,1,5.32.87,8.23,8.23,0,0,1,3.4,2.44,9.24,9.24,0,0,1,1.79,3.77,21,21,0,0,1,.52,4.85v14.6h-6V65.51a20,20,0,0,0-.27-3.55,6.38,6.38,0,0,0-.9-2.39,3.52,3.52,0,0,0-1.69-1.34,7.12,7.12,0,0,0-2.6-.42,19.91,19.91,0,0,0-2.39.15c-.83.1-1.44.18-1.84.25v21H96Z"
        />
        <path
          className="cls-1"
          d="M121.25,66.31a16.36,16.36,0,0,1,1-6A12.55,12.55,0,0,1,125,56a11,11,0,0,1,3.87-2.59,12,12,0,0,1,4.47-.87q5.37,0,8.37,3.33t3,9.94c0,.33,0,.7,0,1.12s0,.78-.07,1.11H127.46a6.83,6.83,0,0,0,2.21,4.85,8.43,8.43,0,0,0,5.69,1.71,19,19,0,0,0,4-.39,16.83,16.83,0,0,0,2.86-.85l.79,4.92a8.9,8.9,0,0,1-1.37.52,20.32,20.32,0,0,1-2,.5c-.75.15-1.55.27-2.41.37a22.84,22.84,0,0,1-2.64.15,15.92,15.92,0,0,1-6-1A11.05,11.05,0,0,1,124.48,76,11.65,11.65,0,0,1,122,71.7,17.46,17.46,0,0,1,121.25,66.31Zm17.44-2.69a8,8,0,0,0-.35-2.36,5.71,5.71,0,0,0-1-1.94A4.85,4.85,0,0,0,135.68,58a5.26,5.26,0,0,0-2.31-.47,5.44,5.44,0,0,0-2.43.52,5.61,5.61,0,0,0-1.77,1.37,6.1,6.1,0,0,0-1.11,1.94,10.24,10.24,0,0,0-.55,2.23Z"
        />
        <path
          className="cls-1"
          d="M155.18,74.85a7.94,7.94,0,0,0,3.48-.57,2,2,0,0,0,1.09-2,2.56,2.56,0,0,0-1.16-2.14,17.84,17.84,0,0,0-3.85-1.84,29.69,29.69,0,0,1-3-1.26,10.21,10.21,0,0,1-2.36-1.57,6.51,6.51,0,0,1-1.56-2.16,7.41,7.41,0,0,1-.58-3.11,6.75,6.75,0,0,1,2.64-5.63A11.26,11.26,0,0,1,157,52.54a21.38,21.38,0,0,1,4.37.43,20.32,20.32,0,0,1,3.13.82l-1.09,4.86a14.9,14.9,0,0,0-6.11-1.19,6.25,6.25,0,0,0-3,.62A2,2,0,0,0,153.2,60a2.73,2.73,0,0,0,.22,1.14,2.42,2.42,0,0,0,.77.92,7.62,7.62,0,0,0,1.44.84,22.69,22.69,0,0,0,2.19.87,31.9,31.9,0,0,1,3.62,1.57,9.43,9.43,0,0,1,2.46,1.74,5.87,5.87,0,0,1,1.42,2.21,8.74,8.74,0,0,1,.45,3A6.43,6.43,0,0,1,163,77.91q-2.76,1.91-7.88,1.91a21.59,21.59,0,0,1-5.51-.57,28.57,28.57,0,0,1-2.93-.92l1-5a25.71,25.71,0,0,0,3.2,1A16.2,16.2,0,0,0,155.18,74.85Z"
        />
        <path
          className="cls-1"
          d="M175.7,45.59a3.44,3.44,0,0,1-1.09,2.68,3.72,3.72,0,0,1-2.58,1,3.8,3.8,0,0,1-2.64-1,3.44,3.44,0,0,1-1.09-2.68,3.53,3.53,0,0,1,1.09-2.74,3.8,3.8,0,0,1,2.64-1,3.72,3.72,0,0,1,2.58,1A3.53,3.53,0,0,1,175.7,45.59Zm-.64,33.58h-6v-26h6Z"
        />
        <path
          className="cls-1"
          d="M189,52.54a14.67,14.67,0,0,1,5,.75,8.11,8.11,0,0,1,3.25,2.08A7.89,7.89,0,0,1,199,58.63a15.79,15.79,0,0,1,.52,4.2V78.58q-1.4.3-4.2.72a43,43,0,0,1-6.33.42,19,19,0,0,1-4.28-.45,8.88,8.88,0,0,1-3.3-1.44,6.84,6.84,0,0,1-2.14-2.58,9,9,0,0,1-.77-3.93,7.54,7.54,0,0,1,.87-3.77A7.1,7.1,0,0,1,181.71,65a10.18,10.18,0,0,1,3.46-1.39,19.45,19.45,0,0,1,4.1-.42,19.08,19.08,0,0,1,2.08.12,18.75,18.75,0,0,1,2.34.42v-1a8,8,0,0,0-.25-2,4.2,4.2,0,0,0-.87-1.66A4.06,4.06,0,0,0,190.93,58a7,7,0,0,0-2.56-.4,22.64,22.64,0,0,0-3.83.3,17.77,17.77,0,0,0-2.83.69L181,53.74a23.41,23.41,0,0,1,3.33-.8A26.47,26.47,0,0,1,189,52.54ZM189.46,75a22.27,22.27,0,0,0,4.23-.3V68c-.33-.09-.82-.19-1.44-.29a13.42,13.42,0,0,0-2.09-.15,13.8,13.8,0,0,0-2,.15,6.48,6.48,0,0,0-1.84.54A3.36,3.36,0,0,0,185,69.36a3.14,3.14,0,0,0-.49,1.81,3.17,3.17,0,0,0,1.34,3A6.86,6.86,0,0,0,189.46,75Z"
        />
        <path
          className="cls-2"
          d="M89.26,9.45h9.32v2.49H89.26V24.38a12.41,12.41,0,0,0,.34,3.23,4.54,4.54,0,0,0,1,1.93,3.05,3.05,0,0,0,1.54.9,8.18,8.18,0,0,0,2,.23,8.47,8.47,0,0,0,3-.43,13.28,13.28,0,0,0,1.77-.79l.72,2.44a10.85,10.85,0,0,1-2.21.93,11.22,11.22,0,0,1-3.44.52,10.17,10.17,0,0,1-3.65-.57,5.21,5.21,0,0,1-2.35-1.72,7,7,0,0,1-1.24-2.87,20.1,20.1,0,0,1-.36-4.07V11.94H82.2V9.45h4.12V2.57L89.26,2Z"
        />
        <path
          className="cls-2"
          d="M121.52,21.17a15.06,15.06,0,0,1-.8,5A11,11,0,0,1,118.51,30a10.14,10.14,0,0,1-3.37,2.47,10.95,10.95,0,0,1-8.6,0A10,10,0,0,1,103.17,30,11.14,11.14,0,0,1,101,26.19a15.06,15.06,0,0,1-.79-5,15.12,15.12,0,0,1,.79-5,11.53,11.53,0,0,1,2.22-3.86,10,10,0,0,1,3.37-2.49,10.95,10.95,0,0,1,8.6,0,10.06,10.06,0,0,1,3.37,2.49,11.34,11.34,0,0,1,2.21,3.86A15.12,15.12,0,0,1,121.52,21.17Zm-3.13,0a11,11,0,0,0-2-7,7.14,7.14,0,0,0-11,0,11,11,0,0,0-2,7,10.9,10.9,0,0,0,2,7,7.2,7.2,0,0,0,11,0A10.9,10.9,0,0,0,118.39,21.17Z"
        />
      </SVG>
    );
  }
}
