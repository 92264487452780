import React from "react";
import styled from "styled-components";
import ComponentBase from "../component-base";
import { colors, fonts, breakpoints } from "../assets/variables/style-variables";

const StyledSectionTitle = styled.h2<ISectionTitleProps>`
  position: relative;
  margin: 72px 0 30px 0;
  font-family: ${fonts.serif};
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.01em;
  line-height: 32px;
  color: ${(props) => props.color || colors.gray.dark};

  @media (min-width: ${breakpoints.vp2}) {
    margin: 120px 0 36px 0;
    font-size: 36px;
    line-height: 48px;
  }

  &:first-child {
    margin-top: 0;
    text-align: ${(props) => (props.isCenterAligned ? "center" : "inherit")};
  }

  &::before {
    content: "";
    position: absolute;
    width: 36px;
    height: 1px;
    top: -12px;
    left: 0;
    right: 0;
    margin: ${(props) => (props.isCenterAligned ? "0 auto" : "0")};
    background-color: ${(props) => props.lineColor || colors.red.light};

    @media (min-width: ${breakpoints.vp2}) {
      width: 48px;
    }
  }
`;

interface ISectionTitleProps {
  color?: string;
  lineColor?: string;
  isCenterAligned?: boolean;
  text: string;
}

export class SectionTitle extends ComponentBase<ISectionTitleProps> {
  public render(): JSX.Element {
    return <StyledSectionTitle {...this.props}>{this.props.text}</StyledSectionTitle>;
  }
}
