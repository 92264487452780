import React from "react";
import styled from "styled-components";
import ComponentBase from "../component-base";
import { colors, fonts } from "../assets/variables/style-variables";
import { Page, Grid, Column } from "./grid";
import { Logo } from "./logo";
import instagramIcon from "../assets/images/icons/instagram.svg";
import twitterIcon from "../assets/images/icons/twitter.svg";
import facebookIcon from "../assets/images/icons/facebook.svg";
import linkedinIcon from "../assets/images/icons/linkedin.svg";

const StyledFooter = styled.footer`
  position: relative;
  padding: 84px 0 24px;
  color: ${colors.gray.light};
  background-color: ${colors.gray.dark};

  &::after {
    content: "";
    position: absolute;
    height: 8px;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${colors.red.base};
  }
`;

const LogoContainer = styled.div`
  margin-bottom: 60px;
`;

const FooterColumn = styled.div`
  margin: 0 0 60px;

  h4 {
    margin-bottom: 24px;
    font-family: ${fonts.serif};
    font-size: 18px;
    color: ${colors.red.light};
  }

  ul li {
    margin: 12px 0;
    font-size: 15px;
    line-height: 24px;

    a {
      color: ${colors.gray.light};
    }
  }

  span {
    color: ${colors.gray.base};
  }
`;

const Socials = styled.div`
  > ul {
    li {
      display: inline-block;
      width: 24px;
      margin: 0 12px 0 0;
    }
  }
`;

const Legal = styled.div`
  margin-top: 24px;
  font-size: 12px;
  line-height: 18px;
  color: ${colors.gray.base};

  a {
    color: inherit;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
`;

export class Footer extends ComponentBase {
  public render(): JSX.Element {
    return (
      <StyledFooter>
        <Page>
          <Grid>
            <Column span={[12, 12, 12, 3]}>
              <LogoContainer>
                <Logo color={colors.gray.light} accentColor={colors.red.light} />
              </LogoContainer>
            </Column>
            <Column span={[12, 12, 12, 3]}>
              <FooterColumn>
                <h4>{this.str.footerProjects}</h4>
                <ul>
                  <li>
                    <a href="/donate">{this.str.footerGivingPlatform}</a>
                  </li>
                  <li>
                    <a href="/nonprofits">{this.str.footerCharityAssessment}</a>
                  </li>
                </ul>
              </FooterColumn>
            </Column>
            <Column span={[12, 12, 12, 3]}>
              <FooterColumn>
                <h4>{this.str.footerContact}</h4>
                <ul>
                  <li>
                    <a href="mailto:info@givetoindonesia.org">info@givetoindonesia.org</a>
                  </li>
                  <li>15127 NE 24th St Ste 766, Redmond, WA 98052</li>
                </ul>
              </FooterColumn>
            </Column>
            <Column span={[12, 12, 12, 3]}>
              <FooterColumn>
                <h4>Follow</h4>
                <Socials>
                  <ul>
                    <li>
                      <a href="https://www.instagram.com/gti.foundation/">
                        <img src={instagramIcon} alt={this.str.footerInstagramAlt} />
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com/gti_foundation">
                        <img src={twitterIcon} alt={this.str.footerTwitterAlt} />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.facebook.com/gti.foundation/">
                        <img src={facebookIcon} alt={this.str.footerFacebookAlt} />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/company/gti-foundation/">
                        <img src={linkedinIcon} alt={this.str.footerLinkedinAlt} />
                      </a>
                    </li>
                  </ul>
                </Socials>
              </FooterColumn>
            </Column>
            <Column span={[12]}>
              <Legal>{this.str.footerCopyright} <a href="/privacy">{this.str.commonPrivacyPolicy}</a>. <a href="/terms">{this.str.commonTermsAndConditions}</a>.</Legal>
            </Column>
          </Grid>
        </Page>
      </StyledFooter>
    );
  }
}
