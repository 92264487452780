import constants from "../constants";
import ReactGA from 'react-ga';
import util from './util';

class Logger {
  constructor() {
    if (!util.isTestEnvironment) {
      ReactGA.initialize(constants.GOOGLE_ANALYTICS_CODE);
    }
  }

  log(category: string, msg: string) {
    if (util.isTestEnvironment) {
      return;
    }
    ReactGA.event({ category, action: msg, label: constants.VERSION });
  }

  logPageView(pathName: string) {
    if (util.isTestEnvironment) {
      return;
    }
    const tokens = pathName.split("/");
    const pageName = tokens[1] ? `/${tokens[1]}` : "/";
    ReactGA.set({ page: pageName })
    ReactGA.pageview(pageName);
  }

  logError(category: string, error: Error) {
    if (util.isTestEnvironment) {
      return;
    }
    if (error.stack) {
      this.log(category, `${error.name} ${error.message} ${error.stack}`);
    } else {
      this.log(category, error.toString());
    }
  }
}

export default new Logger();