import { ILocStrings } from "../strings/interface";
import { INonprofit, INonprofits } from "./interfaces";
import doctorshareHeroImg720 from "../assets/images/nonprofits/doctorshare/hero-doctorshare-720.jpg";
import doctorshareHeroImg1080 from "../assets/images/nonprofits/doctorshare/hero-doctorshare-1080.jpg";
import doctorshareHeroImg1440 from "../assets/images/nonprofits/doctorshare/hero-doctorshare-1440.jpg";
import doctorShareLogo from "../assets/images/nonprofits/doctorshare/doctorshare-logo.svg";
import doctorShareProgram1Img from "../assets/images/nonprofits/doctorshare/program-tile-floating-hospital-1.jpg";
import doctorShareProgram2Img from "../assets/images/nonprofits/doctorshare/program-tile-floating-hospital-2.jpg";
import doctorShareProgram3Img from "../assets/images/nonprofits/doctorshare/program-tile-floating-hospital-3.jpg";
import doctorShareProgram4Img from "../assets/images/nonprofits/doctorshare/program-tile-flying-doctors.jpg";
import doctorShareProgram5Img from "../assets/images/nonprofits/doctorshare/program-tile-nutrition.jpg";
import doctorShareProgram6Img from "../assets/images/nonprofits/doctorshare/program-tile-tbc.jpg";
import doctorShareRegionsMap from "../assets/images/nonprofits/doctorshare/regions.svg";
import yadasHeroImg720 from "../assets/images/nonprofits/yadas/hero-yadas-720.jpg";
import yadasHeroImg1080 from "../assets/images/nonprofits/yadas/hero-yadas-1080.jpg";
import yadasHeroImg1440 from "../assets/images/nonprofits/yadas/hero-yadas-1440.jpg";
import yadasLogo from "../assets/images/nonprofits/yadas/yadas-logo.png";
import yadasProgram1Img from "../assets/images/nonprofits/yadas/program-tile-scholarships.jpg";
import yadasProgram2Img from "../assets/images/nonprofits/yadas/program-tile-hs-seminars.jpg";
import yadasProgram3Img from "../assets/images/nonprofits/yadas/program-tile-coffee.jpg";
import yadasProgram4Img from "../assets/images/nonprofits/yadas/program-tile-school-donations.jpg";
import yadasProgram5Img from "../assets/images/nonprofits/yadas/program-tile-english-courses.jpg";
import yadasRegionsMap from "../assets/images/nonprofits/yadas/regions.svg";

const initNonprofitsData = (str: ILocStrings): INonprofits => {
  return {
    doctorshare: {
      name: "doctorSHARE",
      hero: {
        headingText: "doctorSHARE",
        src: doctorshareHeroImg1080,
        srcVp0: doctorshareHeroImg720,
        srcVp5: doctorshareHeroImg1440,
        alt: str.doctorshareHeroImgAlt,
      },
      summary: {
        title: str.doctorshareSummaryTitle,
        text: str.doctorshareSummary,
        vision: str.doctorShareVision,
        mission: str.doctorShareMission,
      },
      logo: doctorShareLogo,
      aka: "Yayasan Dokter Peduli",
      website: {
        displayText: "doctorshare.org",
        url: "https://www.doctorshare.org/",
      },
      address:
        "Mega Glodok Kemayoran, Blok B, No.10<br />Jl. Angkasa Kav. B-6<br />Kemayoran, Jakarta Pusat 10160<br />Indonesia",
      founded: "2009",
      issueAreas: [str.issueAreaHealthcare, str.issuAreaHumanitarianAid],
      programs: [
        {
          id: "doctorshare-ent",
          title: str.doctorShareProgram1CardTitle,
          description: str.doctorShareProgram1CardDescription,
          imageUrl: doctorShareProgram1Img,
          imageAltText: str.doctorShareProgram1CardImgAlt,
          href: "#",
        },
        {
          id: "doctorshare-floatinghospital2",
          title: str.doctorShareProgram2CardTitle,
          description: str.doctorShareProgram2CardDescription,
          imageUrl: doctorShareProgram2Img,
          imageAltText: str.doctorShareProgram2CardImgAlt,
          href: "#",
        },
        {
          id: "doctorshare-floatinghospital3",
          title: str.doctorShareProgram3CardTitle,
          description: str.doctorShareProgram3CardDescription,
          imageUrl: doctorShareProgram3Img,
          imageAltText: str.doctorShareProgram3CardImgAlt,
          href: "#",
        },
        {
          id: "doctorshare-flyingdoctors",
          title: str.doctorShareProgram4CardTitle,
          description: str.doctorShareProgram4CardDescription,
          imageUrl: doctorShareProgram4Img,
          imageAltText: str.doctorShareProgram4CardImgAlt,
          href: "#",
        },
        {
          id: "doctorshare-nutrition",
          title: str.doctorShareProgram5CardTitle,
          description: str.doctorShareProgram5CardDescription,
          imageUrl: doctorShareProgram5Img,
          imageAltText: str.doctorShareProgram5CardImgAlt,
          href: "#",
        },
        {
          id: "doctorshare-tb",
          title: str.doctorShareProgram6CardTitle,
          description: str.doctorShareProgram6CardDescription,
          imageUrl: doctorShareProgram6Img,
          imageAltText: str.doctorShareProgram6CardImgAlt,
          href: "#",
        },
      ],
      regionsMapUrl: doctorShareRegionsMap,
      impactMeasures: [
        {
          title: str.doctorShareImpactMeasure1Title,
          data: [
            {
              year: 2019,
              value: 201,
            },
            {
              year: 2018,
              value: 276,
            },
          ],
        },
        {
          title: str.doctorShareImpactMeasure2Title,
          data: [
            {
              year: 2019,
              value: 766,
            },
            {
              year: 2018,
              value: 610,
            },
          ],
        },
        {
          title: str.doctorShareImpactMeasure3Title,
          data: [
            {
              year: 2019,
              value: 16,
            },
          ],
        },
        {
          title: str.doctorShareImpactMeasure4Title,
          data: [
            {
              year: 2019,
              value: 19590,
            },
            {
              year: 2018,
              value: 10727,
            },
          ],
        },
        {
          title: str.doctorShareImpactMeasure5Title,
          data: [
            {
              year: 2019,
              value: 619,
            },
            {
              year: 2018,
              value: 485,
            },
          ],
        },
        {
          title: str.doctorShareImpactMeasure6Title,
          data: [
            {
              year: 2019,
              value: 943,
            },
            {
              year: 2018,
              value: 726,
            },
          ],
        },
        {
          title: str.doctorShareImpactMeasure7Title,
          data: [
            {
              year: 2019,
              value: 139,
            },
          ],
        },
        {
          title: str.doctorShareImpactMeasure8Title,
          data: [
            {
              year: 2019,
              value: 543,
            },
          ],
        },
        {
          title: str.doctorShareImpactMeasure9Title,
          data: [
            {
              year: 2019,
              value: 3412,
            },
          ],
        },
        {
          title: str.doctorShareImpactMeasure10Title,
          data: [
            {
              year: 2019,
              value: 1488,
            },
            {
              year: 2018,
              value: 659,
            },
          ],
        },
        {
          title: str.doctorShareImpactMeasure11Title,
          data: [
            {
              year: 2019,
              value: 170,
            },
          ],
        },
      ],
      financials: {
        fiscalYear: 2019,
        revenue: [
          {
            name: str.doctorShareFinancialsRevenueRow1Name,
            value: 14394284730,
          },
          {
            name: str.doctorShareFinancialsRevenueRow2Name,
            value: 138044429,
          },
          {
            name: str.nonprofitProfileFinancialsTotalRevenue,
            value: 14532329159,
          },
        ],
        expenses: [
          {
            name: str.doctorShareFinancialsExpensesRow1Name,
            value: 6003073199,
          },
          {
            name: str.doctorShareFinancialsExpensesRow2Name,
            value: 7340123573,
          },
          {
            name: str.doctorShareFinancialsExpensesRow3Name,
            value: 4209908886,
          },
          {
            name: str.doctorShareFinancialsExpensesRow4Name,
            value: 632754100,
          },
          {
            name: str.nonprofitProfileFinancialsTotalExpenses,
            value: 18185859758,
          },
        ],
        assets: [
          {
            name: str.nonprofitProfileFinancialsTotalAssets,
            value: 15713128005,
          },
          {
            name: str.nonprofitProfileFinancialsTotalLiabilities,
            value: 491307427,
          },
          {
            name: str.nonprofitProfileFinancialsNetAssets,
            value: 15221820578,
          },
        ],
      },
    },
    yayasandairisaroha: {
      name: "Yayasan Dairi Saroha",
      hero: {
        headingText: "Yayasan Dairi Saroha",
        src: yadasHeroImg1080,
        srcVp0: yadasHeroImg720,
        srcVp5: yadasHeroImg1440,
        alt: str.yadasHeroImgAlt,
      },
      summary: {
        title: str.yadasSummaryTitle,
        text: str.yadasSummary,
        vision: str.yadasVision,
        mission: str.yadasMission,
      },
      logo: yadasLogo,
      aka: "Yadas",
      address:
        "Jl. Ciater 2 No. 59<br />Lengkong Wetan, Serpong<br />Tangerang Selatan, Banten 15322<br />Indonesia",
      founded: "2016",
      issueAreas: [str.issueAreaEducation, str.issueAreaLivelihood, str.issueAreaCivilSociety],
      programs: [
        {
          id: "yadas-scholarships",
          title: str.yadasProgram1CardTitle,
          description: str.yadasProgram1CardDescription,
          imageUrl: yadasProgram1Img,
          imageAltText: str.yadasProgram1CardImgAlt,
          href: "#",
        },
        {
          id: "yadas-hsseminars",
          title: str.yadasProgram2CardTitle,
          description: str.yadasProgram2CardDescription,
          imageUrl: yadasProgram2Img,
          imageAltText: str.yadasProgram2CardImgAlt,
          href: "#",
        },
        {
          id: "yadas-coffee",
          title: str.yadasProgram3CardTitle,
          description: str.yadasProgram3CardDescription,
          imageUrl: yadasProgram3Img,
          imageAltText: str.yadasProgram3CardImgAlt,
          href: "#",
        },
        {
          id: "yadas-schooldonations",
          title: str.yadasProgram4CardTitle,
          description: str.yadasProgram4CardDescription,
          imageUrl: yadasProgram4Img,
          imageAltText: str.yadasProgram4CardImgAlt,
          href: "#",
        },
        {
          id: "yadas-englishcourses",
          title: str.yadasProgram5CardTitle,
          description: str.yadasProgram5CardDescription,
          imageUrl: yadasProgram5Img,
          imageAltText: str.yadasProgram5CardImgAlt,
          href: "#",
        },
      ],
      regionsMapUrl: yadasRegionsMap,
      impactMeasures: [
        {
          title: str.yadasImpactMeasure1Title,
          data: [
            {
              year: 2019,
              value: 15,
            },
            {
              year: 2018,
              value: 11,
            },
            {
              year: 2017,
              value: 5,
            },
          ],
        },
        {
          title: str.yadasImpactMeasure2Title,
          data: [
            {
              year: 2016,
              value: 1,
            },
          ],
        },
        {
          title: str.yadasImpactMeasure3Title,
          data: [
            {
              year: 2018,
              value: 1,
            },
            {
              year: 2017,
              value: 1,
            },
          ],
        },
        {
          title: str.yadasImpactMeasure4Title,
          data: [
            {
              year: 2019,
              value: 1,
            },
            {
              year: 2018,
              value: 1,
            },
            {
              year: 2017,
              value: 5,
            },
          ],
        },
        {
          title: str.yadasImpactMeasure5Title,
          data: [
            {
              year: 2018,
              value: 1,
            },
          ],
        },
        {
          title: str.yadasImpactMeasure6Title,
          data: [
            {
              year: 2019,
              value: 2,
            }
          ],
        },
      ],
      financials: {
        fiscalYear: 2020,
        revenue: [
          {
            name: str.yadasFinancialsRevenueRow1Name,
            value: 167188000,
          },
          {
            name: str.yadasFinancialsRevenueRow2Name,
            value: 79546,
          },
          {
            name: str.nonprofitProfileFinancialsTotalRevenue,
            value: 167267546,
          },
        ],
        expenses: [
          {
            name: str.yadasFinancialsExpensesRow1Name,
            value: 131250000,
          },
          {
            name: str.yadasFinancialsExpensesRow2Name,
            value: 24000000,
          },
          {
            name: str.yadasFinancialsExpensesRow3Name,
            value: 19000000,
          },
          {
            name: str.yadasFinancialsExpensesRow4Name,
            value: 9602546,
          },
          {
            name: str.nonprofitProfileFinancialsTotalExpenses,
            value: 183852546,
          },
        ],
        assets: [
          {
            name: str.nonprofitProfileFinancialsTotalAssets,
            value: 257951289,
          },
          {
            name: str.nonprofitProfileFinancialsTotalLiabilities,
            value: 0,
          },
          {
            name: str.nonprofitProfileFinancialsNetAssets,
            value: 257951289,
          },
        ],
      },
    },
  };
};

export const getNonprofitDataById = (id: string, str: ILocStrings): INonprofit => {
  return initNonprofitsData(str)[id];
};

export const getAllNonprofitsData = (str: ILocStrings): INonprofits => {
  return initNonprofitsData(str);
};
