import React from "react";
import styled from "styled-components";
import ComponentBase from "../../component-base";
import { colors } from "../../assets/variables/style-variables";
import { Page, Grid, Column } from "../grid";
import { Hero } from "../hero";
import { SectionTitle } from "../section-title";
import heroImage720 from "../../assets/images/heroes/hero-paddy-720.jpg";
import heroImage1080 from "../../assets/images/heroes/hero-paddy-1080.jpg";
import heroImage1440 from "../../assets/images/heroes/hero-paddy-1440.jpg";
import mobile1 from "../../assets/images/amazonsmile/smile-m1.jpg";
import mobile2 from "../../assets/images/amazonsmile/smile-m2.jpg";
import mobile3 from "../../assets/images/amazonsmile/smile-m3.jpg";
import desktop1 from "../../assets/images/amazonsmile/smile-d1.jpg";
import desktop2 from "../../assets/images/amazonsmile/smile-d2.jpg";
import desktop3 from "../../assets/images/amazonsmile/smile-d3.jpg";

const List = styled.ol`
  li {
    margin-bottom: 12px;
    margin-left: 18px;
    list-style-type: decimal;

    img {
      width: 100%;
      max-width: 360px;
      margin: 18px 0 12px;
      border: 1px solid ${colors.gray.base};
    }
  }
`;

export class AmazonSmile extends ComponentBase {
  public render(): JSX.Element {
    return (
      <>
        <Hero
          headingText={this.str.smileHero}
          backgroundPosition="center bottom"
          alt={this.str.smileHeroImgAlt}
          src={heroImage1080}
          srcVp0={heroImage720}
          srcVp5={heroImage1440}
        />
        <Page backgroundColor={colors.gray.light}>
          <Grid applyPadding={true}>
            <Column span={[12, 12, 8]}>
              <SectionTitle text={this.str.smileHeading} />
              <p>{this.str.smileSubheading}</p>
              <SectionTitle text={this.str.smileDesktopHeading} />
              <List>
                <li dangerouslySetInnerHTML={{ __html: this.str.smileDesktopItem1 }}></li>
                <li>
                  <div>{this.str.smileDesktopItem2}</div>
                  <img src={desktop1} alt={this.str.smileDesktopItem2ImgAlt} style={{ maxWidth: 528 }} />
                </li>
                <li>
                  <div>{this.str.smileDesktopItem3}</div>
                  <img src={desktop2} alt={this.str.smileDesktopItem3ImgAlt} style={{ maxWidth: 528 }} />
                </li>
                <li>
                  <div dangerouslySetInnerHTML={{ __html: this.str.smileDesktopItem4 }}></div>
                  <img src={desktop3} alt={this.str.smileDesktopItem4ImgAlt} style={{ maxWidth: 528 }} />
                </li>
                <li>{this.str.smileDesktopItem5}</li>
              </List>
              <SectionTitle text={this.str.smileMobileHeading} />
              <List>
                <li dangerouslySetInnerHTML={{ __html: this.str.smileMobileItem1 }}></li>
                <li>
                  <div>{this.str.smileMobileItem2}</div>
                  <img src={mobile1} alt={this.str.smileMobileItem2ImgAlt} />
                </li>
                <li>
                  <div>{this.str.smileMobileItem3}</div>
                  <img src={mobile2} alt={this.str.smileMobileItem3ImgAlt} />
                </li>
                <li>
                  <div>{this.str.smileMobileItem4}</div>
                  <img src={mobile3} alt={this.str.smileMobileItem4ImgAlt} />
                </li>
                <li>{this.str.smileMobileItem5}</li>
              </List>
            </Column>
          </Grid>
        </Page>
      </>
    );
  }
}
