import styled from "styled-components";
import { breakpoints } from "../assets/variables/style-variables";

interface IGridProps {
  gutter?: string;
  applyPadding?: boolean;
  applyTopPadding?: boolean;
  applyBottomPadding?: boolean;
}

interface IPageProps {
  backgroundColor?: string;
  height?: string;
}

interface IColumnProps {
  row?: number[] | "auto";
  rowSpan?: number[];
  position?: number[] | "auto";
  span?: number[] | "auto";
}

const generateColumn = ({ position = "auto", span = "auto" }: IColumnProps, index = 0) => {
  const validPositionValue: number | "auto" =
    position === "auto" ? position : position[index] ? position[index] : position[position.length - 1];
  const validSpanValue: number | "auto" = span === "auto" ? span : span[index] ? span[index] : span[span.length - 1];

  return `${validPositionValue} / span ${validSpanValue}`;
};

const getDefaultRow = ({ row = "auto" }: IColumnProps): number | "auto" => {
  return Array.isArray(row) ? row[0] : row;
};

const generateRow = ({ row = [1], rowSpan = [1] }: IColumnProps, index: number) => {
  const validRowValue: number | "auto" = row === "auto" ? row : row[index] ? row[index] : row[row.length - 1];
  const validRowSpanValue: number = rowSpan[0] === 1 ? 1 : rowSpan[index] ? rowSpan[index] : rowSpan[rowSpan.length - 1];

  return `${validRowValue} / span ${validRowSpanValue}`;
};

const generateMediaQuery = (width: number): string => `@media (min-width: ${width}px)`;

export const Page = styled.section<IPageProps>`
  display: grid;
  grid-template-columns: minmax(8vw, 1fr) minmax(auto, 1600px) minmax(8vw, 1fr);
  background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : "transparent")};
  height: ${(props) => (props.height ? props.height : "initial")};

  @media (min-width: ${breakpoints.vp4}) {
    grid-template-columns: minmax(10vw, 1fr) minmax(auto, 1600px) minmax(10vw, 1fr);
  }
`;

export const Grid = styled.div<IGridProps>`
  grid-column: 2;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-auto-rows: auto;
  grid-column-gap: ${(props) => props.gutter};
  padding: ${(props) => (props.applyPadding ? "78px 0 72px" : "")};
  padding-top: ${(props) => (props.applyTopPadding ? "78px" : "")};
  padding-bottom: ${(props) => (props.applyBottomPadding ? "72px" : "")};

  @media (min-width: ${breakpoints.vp2}) {
    padding: ${(props) => (props.applyPadding ? "120px 0 90px" : "")};
    padding-top: ${(props) => (props.applyTopPadding ? "120px" : "")};
    padding-bottom: ${(props) => (props.applyBottomPadding ? "90px" : "")};
  }
`;

export const Column = styled.div<IColumnProps>`
  grid-row: ${getDefaultRow};
  grid-column: ${generateColumn};

  ${(props) =>
    `
      ${generateMediaQuery(320)} {
        grid-column: ${generateColumn(props, 1)};
        grid-row: ${generateRow(props, 1)};
      }

      ${generateMediaQuery(540)} {
        grid-column: ${generateColumn(props, 2)};
        grid-row: ${generateRow(props, 2)};
      }

      ${generateMediaQuery(768)} {
        grid-column: ${generateColumn(props, 3)};
        grid-row: ${generateRow(props, 3)};
      }
      
      ${generateMediaQuery(1024)} {
        grid-column: ${generateColumn(props, 4)};
        grid-row: ${generateRow(props, 4)};
      }
      
      ${generateMediaQuery(1440)} {
        grid-column: ${generateColumn(props, 5)};
        grid-row: ${generateRow(props, 5)};
      }
      
      ${generateMediaQuery(1779)} {
        grid-column: ${generateColumn(props, 6)};
        grid-row: ${generateRow(props, 6)};
      }
  `};
`;

Grid.defaultProps = {
  gutter: "24px",
};

Column.defaultProps = {
  row: "auto",
  rowSpan: [1],
  position: "auto",
  span: "auto",
};
