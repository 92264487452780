import React from "react";
import styled from "styled-components";
import validator from "validator";
import { breakpoints, colors, fonts, styles, transitions } from "../assets/variables/style-variables";
import ComponentBase from "../component-base";

interface IFormLabel {
  isRequired?: boolean;
}

interface IFormTextInput {
  isRequired?: boolean;
  isValid?: boolean;
}

interface IMessage {
  isVisible?: boolean;
}

const FormLabel = styled.label<IFormLabel>`
  &::after {
    display: ${(props) => (props.isRequired ? "" : "none")};
    content: "*";
    margin-left: 2px;
  }
`;

const FormTextInput = styled.input<IFormTextInput>`
  width: 100%;
  margin: 8px 0 36px;
  padding: 12px 18px;
  box-sizing: border-box;
  color: ${colors.gray.dark};
  background-color: ${colors.gray.lightExtra};
  border: 1px solid ${(props) => (props.isValid ? colors.gray.lightMid : colors.red.base)};
  border-radius: ${styles.borderRadius};
  font-family: ${fonts.base};
  font-size: 16px;
  transition: ${transitions.fast};
  transition-property: opacity border-color;

  @media (min-width: ${breakpoints.vp2}) {
    font-size: 18px;
  }

  &::placeholder {
    color: ${colors.gray.base};
  }

  &:focus {
    border-color: ${colors.gray.lightMid};
    background-color: ${colors.white};
  }

  &[type="number"] {
    -moz-appearance: textfield;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      appearance: none;
    }
  }

  &:disabled {
    opacity: 1; // Do nothing for now
  }
`;

const MessageContainer = styled.div`
  position: relative;
  margin: -30px 0 36px;
  font-size: 12px;
  line-height: 18px;

  @media screen and (min-width: 540px) {
    font-size: 15px;
  }
`;

const Message = styled.div<IMessage>`
  position: absolute;
  transition: ${transitions.fast};
  transition-property: opacity;
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
`;

const ErrorMessage = styled(Message)`
  color: ${colors.red.base};
`;

const Note = styled(Message)`
  color: ${colors.gray.base};
  font-style: italic;
`;

interface IFormTextFieldProps {
  id: string;
  label?: string;
  type: string;
  isValid: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
  errorMessage: string;
  regexToIgnore?: RegExp;
  note?: string;
  onBlurCapture?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

interface IFormTextFieldState {
  isValid: boolean;
  isNoteVisible: boolean;
}

export class FormTextField extends ComponentBase<IFormTextFieldProps, IFormTextFieldState> {
  constructor(props: IFormTextFieldProps) {
    super(props);

    this.state = {
      isValid: true,
      isNoteVisible: false,
    };
  }

  public shouldComponentUpdate(
    nextProps: Readonly<IFormTextFieldProps>,
    nextState: Readonly<IFormTextFieldState>
  ): boolean {
    let updateComponent = false;

    // External validation
    if (nextProps.isValid !== this.props.isValid) {
      this.setState({
        isValid: nextProps.isValid,
      });

      updateComponent = true;
    }

    // Internal validation
    if (nextState.isValid !== this.state.isValid) {
      this.setState({
        isValid: nextState.isValid,
      });

      updateComponent = true;
    }

    if (this.props.isDisabled !== nextProps.isDisabled) {
      updateComponent = true;
    }

    return updateComponent;
  }

  public render(): JSX.Element {
    return (
      <div>
        {this.props.label && (
          <FormLabel isRequired={this.props.isRequired} htmlFor={this.props.id}>
            {this.props.label}
          </FormLabel>
        )}
        <FormTextInput
          id={this.props.id}
          name={this.props.id}
          type={this.props.type}
          isRequired={this.props.isRequired}
          isValid={this.state.isValid}
          onBlur={this.validate}
          disabled={this.props.isDisabled}
          onBlurCapture={this.props.onBlurCapture}
        />
        <MessageContainer>
          <ErrorMessage isVisible={!this.state.isValid}>{this.props.errorMessage}</ErrorMessage>
          <Note isVisible={this.state.isNoteVisible}>{this.props.note}</Note>
        </MessageContainer>
      </div>
    );
  }

  private validate = (event: React.ChangeEvent<HTMLInputElement>): void => {
    event.target.value = event.target.value.trim();

    let value: string = event.target.value;
    let isValid = false;

    if (value !== "") {
      if (this.props.regexToIgnore) {
        value = value.replace(this.props.regexToIgnore, "");
      }

      switch (this.props.type) {
        case "email":
          isValid = validator.isEmail(value);
          break;

        case "number":
          isValid = validator.isInt(value, { min: 1 });
          break;

        case "text":
        default:
          isValid = validator.isAlphanumeric(value);
          break;
      }
    } else {
      if (!this.props.isRequired) {
        isValid = true;
      }
    }

    this.setState({
      isValid,
      isNoteVisible: !!(isValid && value.length > 0 && this.props.note),
    });

    this.forceUpdate();
  };
}
