import React from "react";
import styled from "styled-components";
import { HashLink as Link } from "react-router-hash-link";
import ComponentBase from "../component-base";
import { colors, shadows, breakpoints } from "../assets/variables/style-variables";
import { Page, Grid, Column } from "./grid";
import { navHeight } from "./nav";

const inPageNavHeight = 48;

const InPageNavContainer = styled.div`
  position: sticky;
  width: 100%;
  height: 48px;
  top: 90px;
  background-color: ${colors.gray.dark};
  box-shadow: ${shadows.dark.rest};
  z-index: 1;

  ul {
    display: flex;
    height: 48px;
    justify-content: space-between;

    @media (min-width: ${breakpoints.vp2}) {
      justify-content: flex-start;
    }

    li {
      display: inline-block;
      height: 100%;
      margin: 0;

      @media (min-width: ${breakpoints.vp2}) {
        margin: 0 24px 0 0;
      }

      @media (min-width: ${breakpoints.vp4}) {
        margin: 0 36px 0 0;
      }

      a {
        position: relative;
        display: flex;
        height: calc(100% - 2px);
        padding-bottom: 2px;
        align-items: center;
        color: ${colors.gray.lightMid};
        font-size: 13px;

        @media (min-width: ${breakpoints.vp1}) {
          font-size: 14px;
        }

        @media (min-width: ${breakpoints.vp2}) {
          font-size: 15px;
        }

        &:hover,
        &:focus {
          color: ${colors.gray.light};
          text-decoration: none;
        }
      }
    }
  }
`;

export class InPageNav extends ComponentBase {
  public render(): JSX.Element {
    return (
      <InPageNavContainer>
        <Page>
          <Grid>
            <Column span={[12]}>
              <ul>
                <li>
                  <Link to="#summary" scroll={this.handleLinkClick}>
                    {this.str.nonprofitProfileInPageNavItemSummary}
                  </Link>
                </li>
                <li>
                  <Link to="#programs" scroll={this.handleLinkClick}>
                    {this.str.nonprofitProfileInPageNavItemPrograms}
                  </Link>
                </li>
                <li>
                  <Link to="#impact" scroll={this.handleLinkClick}>
                    {this.str.nonprofitProfileInPageNavItemImpact}
                  </Link>
                </li>
                <li>
                  <Link to="#financials" scroll={this.handleLinkClick}>
                    {this.str.nonprofitProfileInPageNavItemFinancials}
                  </Link>
                </li>
              </ul>
            </Column>
          </Grid>
        </Page>
      </InPageNavContainer>
    );
  }

  private handleLinkClick = (e: HTMLElement): void => {
    this.scrollTo(e.id);
  };

  private scrollTo = (id: string): void => {
    const scrollContainer: HTMLElement | null = document.getElementById("app");

    if (!scrollContainer) {
      return;
    }

    scrollContainer.scroll({
      top: (document.getElementById(id)?.offsetTop ?? 0) - inPageNavHeight - navHeight,
      left: 0,
      behavior: "smooth",
    });
  };
}
