import React from "react";
import styled from "styled-components";
import ComponentBase from "../component-base";
import { breakpoints } from "../assets/variables/style-variables";

const Background = styled.div<IImageProps>`
  background-image: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: ${(props) => props.backgroundPosition ?? "center"};

  ${(props) =>
    props.srcVp0
      ? `@media (min-width: ${breakpoints.vp0}) {
      background-image: url(${props.srcVp0});
    }`
      : ""}
  
  ${(props) =>
    props.srcVp1
      ? `@media (min-width: ${breakpoints.vp1}) {
      background-image: url(${props.srcVp1});
    }`
      : ""}

  ${(props) =>
    props.srcVp2
      ? `@media (min-width: ${breakpoints.vp2}) {
        background-image: url(${props.srcVp2});
      }`
      : ""}

  ${(props) =>
    props.srcVp3
      ? `@media (min-width: ${breakpoints.vp3}) {
        background-image: url(${props.srcVp3});
      }`
      : ""}

  @media (min-width: ${breakpoints.vp4}) {
    background-image: url(${(props) => props.src});
  }

  ${(props) =>
    props.srcVp5
      ? `@media (min-width: ${breakpoints.vp5}) {
      background-image: url(${props.srcVp5});
    }`
      : ""}

  ${(props) =>
    props.srcVp6
      ? `@media (min-width: ${breakpoints.vp6}) {
        background-image: url(${props.srcVp6});
      }`
      : ""}
`;

export interface IImageProps {
  src: string;
  srcVp0?: string;
  srcVp1?: string;
  srcVp2?: string;
  srcVp3?: string;
  srcVp5?: string;
  srcVp6?: string;
  alt: string;
  applyAsBackground?: boolean;
  backgroundPosition?: string;
}

export class Image extends ComponentBase<IImageProps, unknown> {
  public render(): JSX.Element {
    if (this.props.applyAsBackground) {
      return <Background {...this.props} />;
    } else {
      return (
        <picture>
          <source srcSet={this.props.srcVp0} media={`(min-width: ${breakpoints.vp0})`} />
          <source srcSet={this.props.srcVp1} media={`(min-width: ${breakpoints.vp1})`} />
          <source srcSet={this.props.srcVp2} media={`(min-width: ${breakpoints.vp2})`} />
          <source srcSet={this.props.srcVp3} media={`(min-width: ${breakpoints.vp3})`} />
          <source srcSet={this.props.src} media={`(min-width: ${breakpoints.vp4})`} />
          <source srcSet={this.props.srcVp5} media={`(min-width: ${breakpoints.vp5})`} />
          <source srcSet={this.props.srcVp6} media={`(min-width: ${breakpoints.vp6})`} />
          <img src={this.props.src} alt={this.props.alt} />
        </picture>
      );
    }
  }
}
