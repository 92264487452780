import React from "react";
import styled from "styled-components";
import ComponentBase from "../component-base";
import { colors, transitions, fonts, styles } from "../assets/variables/style-variables";

interface IStyledButtonProps {
  color?: string;
  colorHover?: string;
  backgroundColor?: string;
  backgroundColorHover?: string;
  outlineColor?: string;
}

const StyledButton = styled.button<IStyledButtonProps>`
  display: block;
  height: 42px;
  padding: 0 24px;
  border: none;
  color: ${(props) => props.color || colors.white};
  background-color: ${(props) => props.backgroundColor || colors.red.base};
  border: 1px solid ${(props) => props.outlineColor || "transparent"};
  border-radius: ${styles.borderRadius};
  font-family: ${fonts.base};
  font-size: 16px;
  transition: ${transitions.fast};
  transition-property: color background-color opacity;

  &:hover,
  &:focus {
    color: ${(props) => props.colorHover || colors.white};
    background-color: ${(props) => props.backgroundColorHover || colors.red.light};
    border: 1px solid ${(props) => props.backgroundColor || colors.red.base};
    cursor: pointer;
  }

  &[disabled] {
    &:hover,
    &:focus {
      color: ${(props) => props.color || colors.white};
      background-color: ${(props) => props.backgroundColor || colors.red.base};
      border: none;
      cursor: default;
    }
  }
`;

interface IButtonProps extends IStyledButtonProps {
  type?: "button" | "submit" | "reset";
  children?: React.ReactNode;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent) => void;
  tabIndex?: number;
}

export class Button extends ComponentBase<IButtonProps, unknown> {
  public render(): JSX.Element {
    return (
      <StyledButton
        type={this.props.type}
        color={this.props.color}
        colorHover={this.props.colorHover}
        backgroundColor={this.props.backgroundColor}
        backgroundColorHover={this.props.backgroundColorHover}
        outlineColor={this.props.outlineColor}
        disabled={this.props.disabled}
        onClick={this.props.onClick}
      >
        {this.props.children}
      </StyledButton>
    );
  }
}
