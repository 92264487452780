import axios from "axios";
import constants from "../constants";
import logger from "./logger";

class Util {
  isTestEnvironment = false;

  constructor() {
    this.isTestEnvironment = !!process.env.JEST_WORKER_ID;
  }

  async verifyRecaptcha(baseFunctionsUrl: string, recaptchaToken?: string, scoreThreshold = constants.RECAPTCHA_SCORE_THRESHOLD_DEFAULT): Promise<{valid: boolean, err?: Error}> {
    const retVal: {valid: boolean, err?: Error} = { valid: false };

    if (!recaptchaToken) {
      const error: Error = new Error("Failed to retrieve reCAPTCHA token");
      logger.logError(constants.logging.SUBSCRIBE, error);
      retVal.err = error;
      return retVal;
    }

    const url = `${baseFunctionsUrl}/HttpRecaptchaVerification`;
    const data = {recaptcha: recaptchaToken,};
  
    try {
      const response = (await axios.post(url, data)).data;
      
      if (response.data.success && response.data.score >= scoreThreshold) {
        logger.log(constants.logging.RECAPTCHA, "success");
        retVal.valid = true;
      } else {
        logger.log(constants.logging.RECAPTCHA, "failed");
        retVal.err = new Error("Fraudulent activity verification failed.");
      }
    } catch (error) {
      retVal.err = error as Error;
      logger.logError(constants.logging.RECAPTCHA, error as Error);
    }
  
    return retVal;
  }
  
}

export default new Util();