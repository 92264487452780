import styled from "styled-components";
import { breakpoints } from "../assets/variables/style-variables";

export const CardGrid = styled.div`
  grid-column: 2;
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 24px;
  padding: 24px 0 72px;

  @media (min-width: ${breakpoints.vp2}) {
    padding: 36px 0 90px;
  }

  @media (min-width: ${breakpoints.vp3}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: ${breakpoints.vp4}) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: ${breakpoints.vp6}) {
    grid-template-columns: repeat(4, 1fr);
  }

  > a {
    margin: 0 0 48px;
  }
`;
