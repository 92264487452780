import React from "react";
import styled from "styled-components";
import ComponentBase from "../component-base";
import { colors, fonts } from "../assets/variables/style-variables";
import { Card, ICardProps } from "./card-base";

const StyledCard = styled(Card) <IContentCardProps>`
  > img {
    width: 100%;
  }

  > div {
    position: relative;
    display: flex;
    flex-direction: column;
    height: ${(props) => (props.textAreaHeight ? props.textAreaHeight : "144px")};
    padding: 24px 30px;

    h3 {
      margin-bottom: 12px;
      font-family: ${fonts.serif};
      font-size: 24px;
      color: ${colors.gray.dark};
    }

    p {
      flex: 1 1 100%;
      font-size: 15px;
      line-height: 21px;
      color: ${colors.gray.dark};
    }

    span {
      font-size: 15px;
      color: ${colors.red.base};
    }
  }
`;

const Cta = styled.span`
  &::after {
    content: "›";
    margin-left: 4px;
  }
`;

export interface IContentCardProps extends ICardProps {
  title: string;
  description: string;
  imageUrl: string;
  imageAltText: string;
  href?: string;
  cta?: string;
  tag?: string;
  textAreaHeight?: string;
}

export class ContentCard extends ComponentBase<IContentCardProps> {
  public render(): JSX.Element {
    return (
      <StyledCard {...this.props} onClick={this.props.onClick}>
        <img src={this.props.imageUrl} alt={this.props.imageAltText} />
        <div>
          <h3>{this.props.title}</h3>
          <p>{this.props.description}</p>
          {this.props.cta && <Cta>{this.props.cta}</Cta>}
          {this.props.tag && <span>{this.props.tag}</span>}
        </div>
      </StyledCard>
    );
  }
}
