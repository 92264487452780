import axios from "axios";
import BraintreeDropIn from "braintree-web-drop-in-react";
import React from "react";
import styled from "styled-components";
import validator from "validator";
import { Dropin, PaymentMethodPayload } from "braintree-web-drop-in";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { IWithGoogleReCaptchaProps, withGoogleReCaptcha } from "react-google-recaptcha-v3";

import { INonprofit, INonprofits, IProgram } from "../data/interfaces";
import { getAllNonprofitsData } from "../data/nonprofits-data";
import ComponentBase from "../component-base";
import constants from "../constants";
import logger from "../services/logger";
import { Button } from "./button";
import { colors, styles, fonts, transitions, breakpoints } from "../assets/variables/style-variables";
import { FormTextField } from "./form-text-field";
import { FormCheckbox } from "./form-checkbox";
import { navHeight } from "./nav";
import { Progress } from "./progress";

interface IForm {
  isDisabled?: boolean;
  isOtherAmountRequired?: boolean;
}

interface IFormLabel {
  isRequired?: boolean;
}

interface IFormSelect {
  isHidden?: boolean;
}

const ErrorMessage = styled.div`
  position: relative;
  padding: 12px 18px;
  margin: 0 0 36px 0;
  color: ${colors.red.base};
  font-size: 18px;
  line-height: 32px;
  border: 1px solid ${colors.red.base};
  border-radius: 4px;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: ${colors.red.base};
    opacity: 0.08;
  }
`;

const Form = styled.form<IForm>`
  display: inline-block;
  width: 100%;
  margin-top: 12px;
  font-size: 16px;
  opacity: ${(props) => (props.isDisabled ? 0.28 : 1)};
  transition: ${transitions.medium};
  transition-property: opacity;

  @media (min-width: ${breakpoints.vp2}) {
    font-size: 18px;
  }

  ul {
    margin: 12px 0 0;

    li {
      height: 36px;
      padding-left: 4px;
      margin: 4px 0 4px -6px;
      transition: ${transitions.medium};
      transition-property: height;
      overflow: hidden;

      &:last-child {
        height: ${(props) => (props.isOtherAmountRequired ? "126px" : "36px")};
      }
    }
  }

  button:last-child {
    margin-top: 36px;
  }
`;

const FormLabel = styled.label<IFormLabel>`
  &::after {
    display: ${(props) => (props.isRequired ? "" : "none")};
    content: "*";
    margin-left: 2px;
  }
`;

const FormSelect = styled.div<IFormSelect>`
  position: relative;
  height: ${(props) => (props.isHidden ? "0" : "66px")};
  margin: 0 0 24px;
  transition: ${transitions.medium};
  transition-property: height;
  overflow: hidden;

  select {
    width: 100%;
    padding: 12px 42px 12px 18px;
    box-sizing: border-box;
    color: ${colors.gray.dark};
    background-color: ${colors.gray.lightExtra};
    border: 1px solid ${colors.gray.lightMid};
    border-radius: ${styles.borderRadius};
    font-family: ${fonts.base};
    font-size: 16px;
    appearance: none;

    @media (min-width: ${breakpoints.vp2}) {
      font-size: 18px;
    }

    &:focus {
      border-color: ${colors.gray.lightMid};
      background-color: ${colors.white};
    }

    option {
      font-size: 14px;
    }
  }

  &::after {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    top: 16px;
    right: 18px;
    border: solid ${colors.gray.base};
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
`;

const FormRadio = styled.input`
  position: relative;
  width: 24px;
  height: 24px;
  margin: 0 8px -5px 0px;
  appearance: none;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: ${colors.gray.lightExtra};
    border: 1px solid ${colors.gray.lightMid};
    border-radius: 50%;
    box-sizing: border-box;
    transition: ${transitions.fast};
    transition-property: border-color;
  }

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: ${colors.gray.base};
    border: 3px solid ${colors.gray.lightMid};
    border-radius: 50%;
    box-sizing: border-box;
    transition: ${transitions.fast};
    transition-property: opacity;
    opacity: 0;
  }

  &:checked {
    &::before {
      border-color: ${colors.gray.base};
    }

    &::after {
      opacity: 1;
    }
  }
`;

const SubmitButtonContainer = styled.div`
  margin: 24px 0 60px;
`;

const Footnotes = styled.div`
  strong {
    display: block;
    margin-bottom: 12px;
    font-size: 15px;
    font-weight: 600;
  }

  > div {
    font-size: 12px;
    line-height: 16px;

    &:first-of-type {
      margin: 24px 0 8px;
    }
  }
`;

interface IDonationComponentProps extends RouteComponentProps, Partial<IWithGoogleReCaptchaProps> {}

interface IDonationFormState {
  clientToken: string | null;
  errorMessage: string | null;
  isCauseSelectionActive: boolean;
  isSubmitting: boolean;
  isOtherAmountRequired: boolean;
  isFirstNameValid: boolean;
  isLastNameValid: boolean;
  isCompanyValid: boolean;
  isEmailValid: boolean;
  isOtherAmountValid: boolean;
  donationAmount: string;
}

class DonationFormComponent extends ComponentBase<IDonationComponentProps, IDonationFormState> {
  private form: React.RefObject<HTMLFormElement>;
  private braintreeDropinInstance: Dropin | null = null;

  constructor(props: IDonationComponentProps) {
    super(props);

    this.form = React.createRef();

    this.state = {
      clientToken: null,
      errorMessage: null,
      isCauseSelectionActive: false,
      isSubmitting: false,
      isOtherAmountRequired: false,
      isFirstNameValid: true,
      isLastNameValid: true,
      isCompanyValid: true,
      isEmailValid: true,
      isOtherAmountValid: true,
      donationAmount: "$20",
    };

    this.handleDonationFormSubmit = this.handleDonationFormSubmit.bind(this);
    this.handleAmountChange = this.handleAmountChange.bind(this);
  }

  public async componentDidMount(): Promise<void> {
    try {
      const clientToken: string = await this.getClientToken();

      this.setState({
        clientToken,
      });
    } catch (err) {
      const error = err as any;
      let errorMessage: string;
      if (error.response) {
        // Currently the error is only 500 Internal Server Error
        errorMessage = this.str.donationFormInternalServerError;
      } else {
        errorMessage = this.str.unknownError;
      }
      this.setState({
        errorMessage,
      });
      logger.logError(constants.logging.DONATE, error);
    }
  }

  public render(): JSX.Element {
    if (!this.state.clientToken) {
      if (this.state.errorMessage) {
        return <ErrorMessage>{this.state.errorMessage}</ErrorMessage>;
      } else {
        return <Progress text={this.str.donationFormLoadingText} />;
      }
    } else {
      return (
        <>
          {!this.state.isSubmitting && this.state.errorMessage && (
            <ErrorMessage>{this.state.errorMessage}</ErrorMessage>
          )}
          {this.state.isSubmitting && <Progress text={this.str.donationFormProcessingText} />}
          {this.generateForm()}
          {this.generateFootnotes()}
        </>
      );
    }
  }

  private generateForm = (): JSX.Element => {
    return (
      <Form
        id="form"
        ref={this.form}
        isDisabled={this.state.isSubmitting}
        isOtherAmountRequired={this.state.isOtherAmountRequired}
        onSubmit={this.handleDonationFormSubmit}
      >
        <FormTextField
          id="first-name"
          label={this.str.donationFormFirstNameLabel}
          type="text"
          regexToIgnore={/[-]/g}
          isRequired={true}
          isValid={this.state.isFirstNameValid}
          isDisabled={this.state.isSubmitting}
          errorMessage={this.str.donationFormFirstNameErrorMessage}
        />
        <FormTextField
          id="last-name"
          label={this.str.donationFormLastNameLabel}
          type="text"
          regexToIgnore={/[-]/g}
          isRequired={true}
          isValid={this.state.isLastNameValid}
          isDisabled={this.state.isSubmitting}
          errorMessage={this.str.donationFormLastNameErrorMessage}
        />
        <FormTextField
          id="company"
          label={this.str.donationFormCompanyLabel}
          type="text"
          regexToIgnore={/[ -']/g}
          isValid={this.state.isCompanyValid}
          isDisabled={this.state.isSubmitting}
          errorMessage={this.str.donationFormCompanyErrorMessage}
          note="Please check if your company could match your donation."
        />
        <FormTextField
          id="email"
          label={this.str.donationFormEmailLabel}
          type="email"
          isRequired={true}
          isValid={this.state.isEmailValid}
          isDisabled={this.state.isSubmitting}
          errorMessage={this.str.donationFormEmailErrorMessage}
        />
        <FormCheckbox id="anonymous" name="anonymous" value="anonymous" label={this.str.donationFormAnonymousLabel} />
        <FormCheckbox
          id="cause-request"
          name="cause-request"
          value="cause-request"
          label={this.str.donationFormCauseRequestLabel}
          hasFootnote={true}
          onChange={this.handleCauseRequestCheckboxChange}
        />
        <FormSelect isHidden={!this.state.isCauseSelectionActive}>
          <select name="cause" id="cause" disabled={!this.state.isCauseSelectionActive || this.state.isSubmitting}>
            {this.generateCauseOptions()}
          </select>
        </FormSelect>
        <FormLabel isRequired={true} htmlFor="amount">
          {this.str.donationFormAmountLabel}
        </FormLabel>
        <ul>
          <li>
            <FormLabel>
              <FormRadio type="radio" name="amount" value="20" onChange={this.handleAmountChange} defaultChecked />
              $20
            </FormLabel>
          </li>
          <li>
            <FormLabel>
              <FormRadio type="radio" name="amount" value="50" onChange={this.handleAmountChange} />
              $50
            </FormLabel>
          </li>
          <li>
            <FormLabel>
              <FormRadio type="radio" name="amount" value="100" onChange={this.handleAmountChange} />
              $100
            </FormLabel>
          </li>
          <li>
            <FormLabel>
              <FormRadio type="radio" name="amount" value="other" onChange={this.handleAmountChange} />
              {this.str.donationFormOtherAmountLabel}
            </FormLabel>
            <FormTextField
              id="other-amount"
              type="number"
              isRequired={this.state.isOtherAmountRequired}
              isValid={this.state.isOtherAmountValid}
              isDisabled={!this.state.isOtherAmountRequired || this.state.isSubmitting}
              errorMessage={this.str.donationFormOtherAmountErrorMessage}
              onBlurCapture={this.handleOtherAmountChange}
            />
          </li>
        </ul>

        {!this.state.isSubmitting && (
          <BraintreeDropIn
            options={{
              authorization: this.state.clientToken!,
              // TODO: add paypal functionality
              venmo: {
                allowNewBrowserTab: false,
              },
            }}
            onInstance={(instance) => (this.braintreeDropinInstance = instance)}
          />
        )}

        <SubmitButtonContainer>
          <Button type="submit">
            {this.str.donationFormDonateButton} {this.state.donationAmount}
          </Button>
        </SubmitButtonContainer>
      </Form>
    );
  };

  private generateCauseOptions = (): JSX.Element[] => {
    const nonprofits: INonprofits = getAllNonprofitsData(this.str);

    // Prepend GTIF as the first option
    const causeOptions: JSX.Element[] = [
      <option key="gtif" value="gtif">
        Give to Indonesia Foundation
      </option>,
    ];

    Object.values(nonprofits).forEach((nonprofit: INonprofit, i: number) => {
      nonprofit.programs.forEach((program: IProgram, j: number) => {
        causeOptions.push(
          <option key={`${i}-${j}`} value={program.id}>
            {nonprofit.name}: {program.title}
          </option>
        );
      });
    });

    return causeOptions;
  };

  private generateFootnotes = (): JSX.Element => {
    return (
      <Footnotes>
        <strong>{this.str.donationFormSecurityMessage}</strong>
        <a
          href="https://www.braintreegateway.com/merchants/kjzt68d8f7knbt7y/verified"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://s3.amazonaws.com/braintree-badges/braintree-badge-light.png"
            width="164px"
            height="44px"
            alt="Braintree badge"
          />
        </a>
        <div>* {this.str.donationFormRequiredFieldDescription}</div>
        <div>† {this.str.donationFormLegalNotes}</div>
      </Footnotes>
    );
  };

  private handleCauseRequestCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({ isCauseSelectionActive: e.target.checked });
  };

  private handleAmountChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const isOtherAmountSelected: boolean = event.target.value === "other";

    this.setState({
      isOtherAmountRequired: isOtherAmountSelected,
      donationAmount: isOtherAmountSelected ? "" : `$${event.target.value}`,
    });
  }

  private handleOtherAmountChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const amount: string = e.target.value;
    const isAmountValid: boolean = amount.trim() !== "" && validator.isInt(amount, { min: 1 }) ? true : false;

    this.setState({
      donationAmount: isAmountValid ? `$${e.target.value}` : "",
    });
  };

  private async getClientToken(): Promise<string> {
    const clientToken: string = (await axios.get(`${this.baseFunctionsUrl}/HttpBraintreeClientToken`)).data.data
      .clientToken;
    return clientToken;
  }

  private handleDonationFormSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    logger.log(constants.logging.DONATE, "submit");
    e.preventDefault();

    if (!this.form.current) {
      return;
    }

    this.checkout(this.form.current);
  };

  private async checkout(formElement: HTMLFormElement): Promise<void> {
    this.scrollToForm();

    // TODO: Consider getting multiple tokens to verify recaptcha twice
    const recaptchaToken = await this.props.googleReCaptchaProps?.executeRecaptcha?.("donationpage");

    if (!recaptchaToken) {
      this.setState({
        errorMessage: "Internal server error.",
        isSubmitting: false,
      });

      logger.logError(constants.logging.SUBSCRIBE, new Error("Failed to retrieve reCAPTCHA token"));

      return;
    }

    const formData: FormData = new FormData(formElement);

    if (!this.isFormDataValid(formData)) {
      return;
    }

    try {
      let paymentMethod: PaymentMethodPayload | undefined;
      try {
        paymentMethod = await this.braintreeDropinInstance?.requestPaymentMethod();
      } catch (paymentMethodError) {
        const errorMessage = this.str.donationFormInvalidInputFieldsErrorMessage;
        this.setState({
          errorMessage,
        });
        logger.logError(constants.logging.DONATE, paymentMethodError as Error);
        return;
      }

      this.setState({
        isSubmitting: true,
      });

      if (paymentMethod) {
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        const response = (
          await axios.post(`${this.baseFunctionsUrl}/HttpBraintreeCheckout`, {
            amount:
              (formData.get("amount") as string) === "other"
                ? (formData.get("other-amount") as string)
                : (formData.get("amount") as string),
            nonce: paymentMethod.nonce,
            customer: {
              company: formData.get("company") as string,
              email: formData.get("email") as string,
              firstName: formData.get("first-name") as string,
              lastName: formData.get("last-name") as string,
            },
            customFields: {
              anonymous: !!(formData.get("anonymous") as string),
              cause: formData.get("cause") as string,
            },
            language: this.str.lang,
            recaptcha: recaptchaToken,
          })
        ).data;

        if (response.data.sessionId) {
          sessionStorage.setItem("session", response.data.sessionId);
          axios.defaults.headers.common["session"] = response.data.sessionId;
        }

        this.props.history.push(`/donation/${response.data.transaction.id}`);
      } else {
        // Case when payment method in not available.
        // Currently this point is not reachable because
        // when there is no payment method, it will go to the
        // catch block on the previous paymentMethod try/catch block.
        throw new Error("Please correct the invalid field(s)");
      }
    } catch (err) {
      const error = err as any;
      let errorMessage: string;
      if (error.response) {
        if (error.response.status === 500) {
          errorMessage = this.str.donationFormInternalServerError;
        } else if (error.response.data.error.errors?.length > 0) {
          errorMessage =
            "Error " + error.response.data.error.errors[0].code + ": " + this.str.donationFormPaymentFailedErrorMessage;
        } else {
          // This error message comes from the API, so it will be non-localized.
          errorMessage = error.response.data.error.message;
        }
      } else {
        errorMessage = this.str.unknownError;
      }
      this.setState({
        errorMessage,
        isSubmitting: false,
      });
      logger.logError(constants.logging.DONATE, error);
    }
  }

  private isFormDataValid(formData: FormData): boolean {
    let isFirstNameValid = false;
    let isLastNameValid = false;
    let isCompanyValid = false;
    let isEmailValid = false;
    let isOtherAmountValid = false;

    const firstName = formData.get("first-name") as string;
    const lastName = formData.get("last-name") as string;
    const company = formData.get("company") as string;
    const email = formData.get("email") as string;
    const amount =
      (formData.get("amount") as string) === "other"
        ? (formData.get("other-amount") as string)
        : (formData.get("amount") as string);

    if (firstName.trim() !== "" && validator.isAlphanumeric(firstName.replace(/[-]/g, ""))) {
      isFirstNameValid = true;
    }
    if (lastName.trim() !== "" && validator.isAlphanumeric(lastName.replace(/[-]/g, ""))) {
      isLastNameValid = true;
    }
    // Allow white space and dash in company names
    if (company.trim() === "" || validator.isAlphanumeric(company.replace(/[ -']/g, ""))) {
      isCompanyValid = true;
    }
    if (email.trim() !== "" && validator.isEmail(email)) {
      isEmailValid = true;
    }
    if (amount.trim() !== "" && validator.isInt(amount, { min: 1 })) {
      isOtherAmountValid = true;
    }

    const isValid = isFirstNameValid && isLastNameValid && isCompanyValid && isEmailValid && isOtherAmountValid;

    if (!isValid) {
      this.setState({
        isFirstNameValid,
        isLastNameValid,
        isCompanyValid,
        isEmailValid,
        isOtherAmountValid,
        errorMessage: this.str.donationFormInvalidInputFieldsErrorMessage,
      });
    }

    return isValid;
  }

  private scrollToForm = (): void => {
    const scrollContainer: HTMLElement | null = document.getElementById("app");

    if (scrollContainer) {
      scrollContainer.scroll({
        top: (document.getElementById("donate")?.offsetTop ?? 0) - navHeight,
        left: 0,
        behavior: "smooth",
      });
    }
  };
}

export const DonationForm = withGoogleReCaptcha(withRouter(DonationFormComponent));
