import React from "react";
import ComponentBase from "../../component-base";
import { Page, Grid, Column } from "../grid";
import { Hero } from "../hero";
import { SectionTitle } from "../section-title";
import { DonationForm } from "../donation-form";
import heroImage720 from "../../assets/images/heroes/hero-paddy-720.jpg";
import heroImage1080 from "../../assets/images/heroes/hero-paddy-1080.jpg";
import heroImage1440 from "../../assets/images/heroes/hero-paddy-1440.jpg";
import { colors } from "../../assets/variables/style-variables";

export class Donation extends ComponentBase {
  public componentDidMount(): void {
    const script: HTMLScriptElement = document.createElement("script");

    script.src = "https://js.braintreegateway.com/web/dropin/1.25.0/js/dropin.min.js";
    script.async = true;

    document.body.appendChild(script);
  }

  public render(): JSX.Element {
    return (
      <>
        <Hero
          headingText={this.str.donationHeroHeading}
          backgroundPosition="center bottom"
          alt={this.str.smileHeroImgAlt}
          src={heroImage1080}
          srcVp0={heroImage720}
          srcVp5={heroImage1440}
        />
        <Page id="donate" backgroundColor={colors.gray.light}>
          <Grid applyPadding={true}>
            <Column span={[12, 12, 8]}>
              <SectionTitle text={this.str.donationTitle} />
              <DonationForm />
            </Column>
          </Grid>
        </Page>
      </>
    );
  }
}
