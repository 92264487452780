import React from "react";
import constants from "./constants";
import { ILocStrings } from "./strings/interface";

export default class ComponentBase<P = Record<string, unknown>, S = Record<string, unknown>> extends React.Component<
  P,
  S
> {
  str: ILocStrings = (window as any).str;

  vars: { [key: string]: string } = (window as any).envVarsJson || {};

  baseFunctionsUrl: string = this.vars["GTIFWEB_BASE_FUNCTIONS_URL"] || constants.DEFAULT_BASE_FUNCTIONS_URL;
}
