import React from "react";
import ComponentBase from "../component-base";
import { Page, Grid, Column } from "./grid";
import { SectionTitle } from "./section-title";
import { NewsletterForm } from "./newsletter-form";

export class Contact extends ComponentBase {
  public render(): JSX.Element {
    return (
      <Page id="contact-us">
        <Grid applyPadding={true}>
          <Column span={[12, 12, 12, 5, 7]}>
            <SectionTitle text={this.str.contactHeading} />
            <p>
              {this.str.contactParagraph1}{" "}
              <a href="mailto:info@givetoindonesia.org">info@givetoindonesia.org</a>.
            </p>
            <p>
              {this.str.contactParagraph2}
            </p>
          </Column>
          <Column span={[12, 12, 12, 6, 4]} position={[1, 1, 1, 7, 9]}>
            <NewsletterForm />
          </Column>
        </Grid>
      </Page>
    );
  }
}
