import React from "react";
import styled from "styled-components";
import { breakpoints, colors, transitions } from "../assets/variables/style-variables";
import ComponentBase from "../component-base";

interface IFormCheckboxContainer {
  hasFootnote?: boolean;
}

const Container = styled.div<IFormCheckboxContainer>`
  display: flex;
  align-items: center;
  margin: 0 0 24px;

  input[type="checkbox"] {
    position: relative;
    width: 24px;
    height: 24px;
    margin: 0 8px 2px 0;
    appearance: none;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: ${colors.gray.lightExtra};
      border: 1px solid ${colors.gray.lightMid};
      border-radius: 4px;
      box-sizing: border-box;
      transition: ${transitions.fast};
      transition-property: background-color border-color;
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 2px;
      left: 8px;
      width: 6px;
      height: 13px;
      border-style: solid;
      border-color: ${colors.gray.lightExtra};
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
      opacity: 0;
      transition: ${transitions.fast};
      transition-property: opacity;
    }

    &:checked {
      &::before {
        background-color: ${colors.gray.base};
        border-color: ${colors.gray.base};
      }

      &::after {
        opacity: 1;
      }
    }
  }

  label {
    position: relative;
    font-size: 14px;

    @media (min-width: ${breakpoints.vp2}) {
      font-size: inherit;
    }

    &::after {
      content: "†";
      display: ${(props) => (props.hasFootnote ? "" : "none")};
      position: absolute;
      right: -6px;
      bottom: 8px;
      font-size: 12px;
    }
  }
`;

interface IFormCheckboxProps extends IFormCheckboxContainer {
  id: string;
  name: string;
  value: string;
  label: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export class FormCheckbox extends ComponentBase<IFormCheckboxProps> {
  public render(): JSX.Element {
    return (
      <Container hasFootnote={this.props.hasFootnote}>
        <input type="checkbox" id={this.props.id} name={this.props.name} value={this.props.value} onChange={this.props.onChange} />
        <label htmlFor={this.props.id}>{this.props.label}</label>
      </Container>
    );
  }
}
