import React from "react";
import { HashLink as Link, HashLinkProps } from "react-router-hash-link";
import styled from "styled-components";
import ComponentBase from "../component-base";
import { colors, transitions, fonts, styles } from "../assets/variables/style-variables";

const StyledLinkButton = styled(Link)<ILinkButtonProps>`
  display: inline-block;
  height: 42px;
  padding: 0 24px;
  border: none;
  color: ${(props) => props.color || colors.white};
  background-color: ${(props) => props.backgroundcolor || colors.red.base};
  border: 1px solid ${(props) => props.outlinecolor || "transparent"};
  border-radius: ${styles.borderRadius};
  font-family: ${fonts.base};
  font-size: 16px;
  line-height: 42px;
  transition: ${transitions.fast};
  transition-property: color background-color opacity;

  &:hover,
  &:focus {
    color: ${(props) => props.colorhover || colors.white};
    background-color: ${(props) => props.backgroundcolorhover || colors.red.light};
    border: 1px solid ${(props) => props.backgroundcolor || colors.red.base};
    cursor: pointer;
  }

  &[disabled] {
    &:hover,
    &:focus {
      color: ${(props) => props.color || colors.white};
      background-color: ${(props) => props.backgroundcolor || colors.red.base};
      border: none;
      cursor: default;
    }
  }
`;

interface ILinkButtonProps extends HashLinkProps {
  color?: string;
  colorhover?: string;
  backgroundcolor?: string;
  backgroundcolorhover?: string;
  outlinecolor?: string;
}

export class LinkButton extends ComponentBase<ILinkButtonProps, unknown> {
  public render(): JSX.Element {
    return (
      <StyledLinkButton
        to={this.props.to}
        color={this.props.color}
        colorhover={this.props.colorhover}
        backgroundcolor={this.props.backgroundcolor}
        backgroundcolorhover={this.props.backgroundcolorhover}
        outlinecolor={this.props.outlinecolor}
        scroll={this.props.scroll}
        onClick={this.props.onClick}
      >
        {this.props.children}
      </StyledLinkButton>
    );
  }
}
