import React from "react";
// import styled from "styled-components";
// import { HashLink as Link } from "react-router-hash-link";
import { INonprofit } from "../data/interfaces";
import ComponentBase from "../component-base";
// import { colors } from "../assets/variables/style-variables";
import { Page, Grid, Column } from "./grid";
import { SectionTitle } from "./section-title";
import { CardGrid } from "./card-grid";
import { ImpactCard, IImpactCardProps } from "./impact-card";

// const ProgramCard = styled(Card)`
//   display: block;
//   div {
//     height: 156px !important;
//   }
// `;

interface INonprofitProfileImpactProps {
  data: INonprofit;
}

export class NonprofitProfileImpact extends ComponentBase<INonprofitProfileImpactProps> {
  public render(): JSX.Element {
    return (
      <Page id="impact">
        <Grid applyTopPadding={true}>
          <Column span={[12, 12, 8]}>
            <SectionTitle text={this.str.nonprofitProfileImpactTitle} />
            <p>{`${this.str.nonprofitProfileImpactDescription} ${this.props.data.name}.`}</p>
          </Column>
        </Grid>
        <CardGrid>{this.generateImpactMeasureCards()}</CardGrid>
      </Page>
    );
  }

  private generateImpactMeasureCards = (): JSX.Element[] => {
    return this.props.data.impactMeasures.map((impactMeasure: IImpactCardProps, index: number) => {
      return <ImpactCard key={index} title={impactMeasure.title} data={impactMeasure.data} />;
    });
  };
}
