import React from "react";
import styled from "styled-components";
import { INonprofit } from "../data/interfaces";
import ComponentBase from "../component-base";
import { colors, fonts } from "../assets/variables/style-variables";
import { Page, Grid, Column } from "./grid";
import { SectionTitle } from "./section-title";

export interface INonprofitProfileFinancialDataRow {
  name: string;
  value: number;
}

const StyledPage = styled(Page)`
  color: ${colors.gray.light};
  background-color: ${colors.gray.dark};

  h2 {
    color: ${colors.gray.light};
  }
`;

const FinancialDataColumn = styled(Column)`
  margin-top: 48px;

  h3 {
    margin-bottom: 12px;
    color: ${colors.red.light};
    font-family: ${fonts.serif};
    font-size: 18px;
  }

  span {
    display: block;
    font-size: 15px;
  }
`;

const FinancialTable = styled.div`
  h4 {
    font-family: ${fonts.serif};
    margin: 48px 0 12px;
    font-size: 18px;
  }

  > div {
    display: flex;
    width: 100%;
    justify-content: space-between;
    border-style: solid;
    border-color: ${colors.gray.darkMid};
    border-top-width: 1px;

    span {
      margin: 0;
      padding: 12px 0 12px 12px;
    }

    &:last-child {
      font-weight: 500;
      border-bottom-width: 1px;

      span {
        padding: 12px 0;
      }
    }
  }
`;

interface INonprofitProfileFinancialsProps {
  data: INonprofit;
}

export class NonprofitProfileFinancials extends ComponentBase<INonprofitProfileFinancialsProps> {
  public render(): JSX.Element {
    return (
      <StyledPage id="financials">
        <Grid applyPadding={true}>
          <Column span={[12, 12, 12, 8]}>
            <SectionTitle text={this.str.nonprofitProfileFinancialsTitle} />
            <p>{`${this.str.nonprofitProfileFinancialsDescription} ${this.props.data.name} (${this.str.nonprofitProfileFinancialsCurrencyNote}).`}</p>
          </Column>
          <FinancialDataColumn span={[12, 12, 12, 6, 5]}>
            <h3>{this.str.nonprofitProfileFinancialsRevenueExpenses}</h3>
            <span>{`${this.str.nonprofitProfileFinancialsFiscalYear} ${this.props.data.financials.fiscalYear}`}</span>
            <FinancialTable>
              <h4>{this.str.nonprofitProfileFinancialsRevenue}</h4>
              {this.generateFinancialTableRows(this.props.data.financials.revenue)}
            </FinancialTable>
            <FinancialTable>
              <h4>{this.str.nonprofitProfileFinancialsExpenses}</h4>
              {this.generateFinancialTableRows(this.props.data.financials.expenses)}
            </FinancialTable>
          </FinancialDataColumn>
          <FinancialDataColumn span={[12, 12, 12, 6, 5]} position={[1, 1, 1, 7, 8]}>
            <h3>{this.str.nonprofitProfileFinancialsBalanceSheet}</h3>
            <span>{`${this.str.nonprofitProfileFinancialsFiscalYear} ${this.props.data.financials.fiscalYear}`}</span>
            <FinancialTable>
              <h4>{this.str.nonprofitProfileFinancialsAssets}</h4>
              {this.generateFinancialTableRows(this.props.data.financials.assets)}
            </FinancialTable>
          </FinancialDataColumn>
        </Grid>
      </StyledPage>
    );
  }

  private generateFinancialTableRows = (data: INonprofitProfileFinancialDataRow[]): JSX.Element[] => {
    return data.map((rowData: INonprofitProfileFinancialDataRow, index: number) => {
      return (
        <div key={index}>
          <span>{rowData.name}</span>
          <span>{rowData.value.toLocaleString(this.str.lang === "en" ? "en-US" : "in-ID")}</span>
        </div>
      );
    });
  };
}
