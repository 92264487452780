import React, { ReactElement } from "react";
import styled from "styled-components";
import ComponentBase from "../component-base";
import { colors, shadows, transitions, styles } from "../assets/variables/style-variables";

const StyledCard = styled.a<ICardProps>`
  display: block;
  background-color: ${colors.gray.light};
  border-radius: ${styles.borderRadius};
  overflow: hidden;
  box-shadow: ${shadows.light.rest};
  transform: translate3d(0, 0, 0);
  transition: ${transitions.fast};
  transition-property: transform box-shadow;

  &:hover,
  &:focus {
    box-shadow: ${(props) => (props.onClick || props.href ? shadows.light.hover : "")};
    transform: ${(props) => (props.onClick || props.href ? "translate3d(0, -1px, 0)" : "")};
    cursor: ${(props) => (props.onClick || props.href ? "pointer" : "default")};
  }
`;

export interface ICardProps {
  onClick?: (e: React.MouseEvent) => void;
  children?: ReactElement[] | ReactElement;
}

export class Card extends ComponentBase<ICardProps> {
  public render(): JSX.Element {
    return (
      <StyledCard {...this.props} onClick={this.props.onClick}>
        {this.props.children}
      </StyledCard>
    );
  }
}
