import React from "react";
import ComponentBase from "../../component-base";
import { Hero } from "../hero";
import { About } from "../about";
import { Projects } from "../projects";
import { Contact } from "../contact";
import heroImage720 from "../../assets/images/heroes/hero-home-720.jpg";
import heroImage1080 from "../../assets/images/heroes/hero-home-1080.jpg";
import heroImage1440 from "../../assets/images/heroes/hero-home-1440.jpg";

export class Home extends ComponentBase {
  public render(): JSX.Element {
    return (
      <>
        <Hero
          headingText={this.str.homeHeroHeading}
          fullScreen={true}
          alt={this.str.homeHeroImgAlt}
          src={heroImage1080}
          srcVp0={heroImage720}
          srcVp5={heroImage1440}
        />
        <About />
        <Projects />
        <Contact />
      </>
    );
  }
}
