import React from "react";
import styled from "styled-components";
import ComponentBase from "../component-base";
import { colors, shadows, breakpoints, fonts } from "../assets/variables/style-variables";
import { Page, Grid, Column } from "./grid";
import { IImageProps, Image } from "./image";

//#region Styled Components

interface IHeroContainerProps {
  fullScreen?: boolean;
}

interface IHeroTextProps {
  headingText?: string;
}

const HeroContainer = styled.div<IHeroContainerProps>`
  display: block;
  position: relative;
  height: 64vh;
  min-height: 600px;
  max-height: 800px;
  margin-top: -120px;
  box-shadow: ${shadows.light.static};
  transform-style: preserve-3d;
  z-index: -1;

  @media (min-width: ${breakpoints.vp2}) {
    height: ${(props) => (props.fullScreen ? "88vh" : "64vh")};
    max-height: 1080px;
  }

  @media (min-width: ${breakpoints.vp3}) {
    height: ${(props) => (props.fullScreen ? "100vh" : "64vh")};
    max-height: none;
  }
`;

const HeroImage = styled(Image)<IImageProps>`
  position: relative;
  height: 100%;
  transform: translateZ(-8px) scale(2);
  transform-origin: 0 0;

  &::before {
    position: absolute;
    content: "";
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.18);
  }

  &::after {
    position: absolute;
    content: "";
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(transparent 36%, rgba(0, 0, 0, 0.8));
  }
`;

const Contents = styled(Page)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
`;

const HeroText = styled.div<IHeroTextProps>`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: flex-end;

  > div {
    display: ${(props) => (props.headingText !== "" ? "block" : "none")};
    width: 120px;
    height: 1px;
    margin: 0 0 18px 4px;
    background-color: ${colors.red.light};

    @media (min-width: ${breakpoints.vp2}) {
      margin: 0 0 24px 4px;
    }
  }

  h2 {
    margin-bottom: 36px;
    color: ${colors.gray.light};
    font-family: ${fonts.serif};
    font-size: 32px;
    font-weight: 300;
    letter-spacing: -0.01em;
    line-height: 36px;

    @media (min-width: ${breakpoints.vp2}) {
      margin-bottom: 72px;
      font-size: 48px;
      line-height: 60px;
    }
  }
`;

//#endregion Styled Components

export interface IHeroProps extends IImageProps, IHeroContainerProps, IHeroTextProps {}

export class Hero extends ComponentBase<IHeroProps> {
  public render(): JSX.Element {
    return (
      <HeroContainer fullScreen={this.props.fullScreen}>
        <HeroImage {...this.props} applyAsBackground={true} />
        <Contents>
          <Grid>
            <Column span={[12, 12, 10, 8, 6, 5]}>
              <HeroText>
                <div />
                <h2>{this.props.headingText}</h2>
              </HeroText>
            </Column>
          </Grid>
        </Contents>
      </HeroContainer>
    );
  }
}
