import React from "react";
import styled, { keyframes } from "styled-components";

const progressing = keyframes`
  0% {
    content: ".";
  }

  20% {
    content: "..";
  }

  40% {
    content: "...";
  }

  60% {
    content: "....";
  }
`;

const ProgressAnimation = styled.div`
  margin-bottom: 24px;
  font-weight: 500;

  &::after {
    content: "....";
    margin-left: 2px;
    font-size: 18px;
    line-height: 32px;
    font-weight: 500;
    animation: ${progressing} 2s linear infinite;
  }
`;

interface IProgressProps {
  text?: string;
}

export class Progress extends React.Component<IProgressProps> {
  public render(): JSX.Element {
    return <ProgressAnimation>{this.props.text}</ProgressAnimation>;
  }
}
