import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import styled from "styled-components";
import throttle from "lodash.throttle";
import ComponentBase from "../component-base";
import { colors, fonts, shadows, transitions, breakpoints } from "../assets/variables/style-variables";
import { Page, Grid, Column } from "./grid";
import { LinkButton } from "./link-button";
import { Logo } from "./logo";

export const navHeight = 90;

//#region Styled Components

interface INavComponentProps {
  isExpanded?: boolean;
  isTransparent?: boolean;
}

const SecondaryNav = styled(Page)`
  display: none;
  position: relative;
  height: 30px;
  z-index: 1;
  color: ${colors.gray.light};
  background-color: ${colors.gray.dark};

  @media (min-width: ${breakpoints.vp3}) {
    display: grid;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
      margin: 0;
      padding: 0;
      font-size: 12px;
      line-height: 24px;
      color: ${colors.gray.base};
      background-color: transparent;
      border: none;
      cursor: pointer;

      &:hover,
      &:focus {
        color: ${colors.gray.light};
      }
    }
  }
`;

const PrimaryNavContainer = styled.div<INavComponentProps>`
  position: sticky;
  width: 100%;
  top: 0;
  background-color: ${(props) => (props.isTransparent ? "rgba(0, 0, 0, .28)" : colors.gray.light)};
  box-shadow: ${(props) => (props.isTransparent ? "none" : shadows.dark.rest)};
  transition: ${transitions.medium};
  transition-property: background-color box-shadow;
  z-index: 2;

  ul li {
    margin: 0;

    a:hover,
    a:focus {
      text-decoration: none;
    }
  }
`;

const PrimaryNav = styled(Page)`
  height: ${navHeight}px;
`;

const LogoContainer = styled(Column)`
  display: flex;
  height: 100%;
  align-items: center;
  z-index: 1;
`;

const Hamburger = styled.button<INavComponentProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  height: 18px;
  top: 24px;
  right: 8vw;
  padding: 12px;
  background-color: transparent;
  border: none;
  box-sizing: content-box;
  cursor: pointer;

  @media (min-width: ${breakpoints.vp2}) {
    right: 5vw;
  }

  @media (min-width: ${breakpoints.vp3}) {
    display: none;
  }

  &:focus,
  &:active {
    outline: 1px solid ${colors.red.base};
  }

  div {
    position: relative;
    width: 100%;
    height: 2px;
  }

  span {
    display: block;
    width: 28px;
    height: 2px;
    text-indent: 200%;
    background-color: ${(props) => (props.isTransparent ? colors.gray.light : colors.gray.dark)};
    transition: ${transitions.medium};
    transition-property: opacity transform;
    overflow: hidden;
  }
`;

const HamburgeBun = styled.span<INavComponentProps>`
  opacity: ${(props) => (props.isExpanded ? 0 : 1)};
`;

const HamburgerVeggie = styled.span<INavComponentProps>`
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(${(props) => (props.isExpanded ? "45deg" : 0)});
`;

const HamburgerPatty = styled.span<INavComponentProps>`
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(${(props) => (props.isExpanded ? "-45deg" : 0)});
`;

const NavContentsMobile = styled(Column)<INavComponentProps>`
  position: absolute;
  width: calc(100% - 10vw);
  height: ${(props) => (props.isExpanded ? "100vh" : "0")};
  top: 0;
  left: 0;
  padding: 0 8vw;
  background-color: ${colors.gray.light};
  transition: height ${transitions.medium};
  overflow: hidden;

  @media (min-width: ${breakpoints.vp2}) {
    padding: 0 5vw;
  }

  @media (min-width: ${breakpoints.vp3}) {
    display: none;
  }

  > div {
    transition: opacity ${transitions.medium};
    opacity: ${(props) => (props.isExpanded ? 1 : 0)};

    ul {
      margin-top: 132px;

      li {
        display: block;

        a,
        button {
          display: block;
          position: relative;
          width: 100%;
          margin-bottom: 18px;
          padding: 10px 0;
          font-family: ${fonts.serif};
          font-size: 24px;
          font-weight: 600;
          letter-spacing: -0.01em;
          text-align: left;
          color: ${colors.gray.base};
          background-color: transparent;
          border: none;
          transition: color ${transitions.fast};

          &:hover {
            color: ${colors.red.base};
            cursor: pointer;
          }

          &::before {
            content: "";
            position: absolute;
            width: 36px;
            height: 1px;
            top: 0;
            left: 0;
            background-color: ${colors.red.light};
          }
        }
      }
    }

    > a {
      margin-top: 18px;
    }
  }
`;

const NavContentsDesktop = styled(Column)<INavComponentProps>`
  display: none;
  height: 100%;
  align-items: center;
  justify-content: flex-end;

  @media (min-width: ${breakpoints.vp3}) {
    display: flex;
  }

  > ul {
    height: 100%;
    margin: 0 24px 0 0;

    @media (min-width: ${breakpoints.vp4}) {
      margin: 0 48px 0 0;
    }

    li {
      display: inline-block;
      height: 100%;

      a {
        position: relative;
        display: flex;
        height: 100%;
        padding: 0 18px;
        align-items: center;
        color: ${(props) => (props.isTransparent ? colors.gray.light : colors.gray.dark)};
        font-size: 16px;
        transition: ${transitions.medium};
        transition-property: color;

        @media (min-width: ${breakpoints.vp4}) {
          padding: 0 24px;
        }

        &::after {
          content: "";
          position: absolute;
          width: calc(100% - 48px);
          height: 0;
          bottom: 0;
          background-color: ${(props) => (props.isTransparent ? colors.gray.light : colors.red.base)};
          transition: ${transitions.fast};
          transition-property: height background-color;
        }

        &:hover,
        &:focus {
          &::after {
            height: 4px;
          }
        }
      }
    }
  }
`;

//#endregion Styled Components

interface INavProps {
  isScrolledToTop?: boolean;
  onNavToggle?: (isNavExpanded: boolean) => void;
}

interface INavStates {
  isExpanded: boolean;
  lang: string;
}

export class Nav extends ComponentBase<INavProps, INavStates> {
  constructor(props: INavProps) {
    super(props);

    this.state = {
      isExpanded: false,
      lang: this.str.lang,
    };
  }

  public componentDidMount(): void {
    this.handleWindowResize();

    window.addEventListener("resize", throttle(this.handleWindowResize, 200));
  }

  public componentWillUnmount(): void {
    window.removeEventListener("resize", throttle(this.handleWindowResize, 200));
  }

  public render(): JSX.Element {
    const isTransparent: boolean | undefined = this.props.isScrolledToTop && !this.state.isExpanded;

    return (
      <>
        <SecondaryNav>
          <Grid>
            <div>{this.generateLangButton()}</div>
          </Grid>
        </SecondaryNav>
        <PrimaryNavContainer isTransparent={isTransparent}>
          <PrimaryNav>
            <Grid>
              <LogoContainer span={[2]}>
                <Link to="/#home" smooth scroll={this.handleLinkClick}>
                  <Logo
                    color={isTransparent ? colors.gray.light : colors.gray.dark}
                    accentColor={isTransparent ? colors.gray.light : colors.red.base}
                  />
                </Link>
              </LogoContainer>
              <NavContentsMobile isExpanded={this.state.isExpanded} isTransparent={isTransparent}>
                <div>
                  <ul>
                    <li>
                      <a href="/nonprofits" tabIndex={this.state.isExpanded ? 0 : -1}>
                        {this.str.navNonprofits}
                      </a>
                    </li>
                    <li>
                      <Link
                        to="/#who-we-are"
                        smooth
                        tabIndex={this.state.isExpanded ? 0 : -1}
                        scroll={this.handleLinkClick}
                      >
                        {this.str.navWho}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/#contact-us"
                        smooth
                        tabIndex={this.state.isExpanded ? 0 : -1}
                        scroll={this.handleLinkClick}
                      >
                        {this.str.navContact}
                      </Link>
                    </li>
                    <li>{this.generateLangButton()}</li>
                  </ul>
                  <LinkButton
                    to="/donate"
                    color={isTransparent ? colors.gray.light : undefined}
                    colorhover={isTransparent ? colors.gray.dark : undefined}
                    backgroundcolor={isTransparent ? "transparent" : undefined}
                    backgroundcolorhover={isTransparent ? colors.gray.light : undefined}
                    outlinecolor={isTransparent ? colors.gray.light : undefined}
                    tabIndex={this.state.isExpanded ? 0 : -1}
                    onClick={this.handleDonateButtonClick}
                  >
                    {this.str.navDonate}
                  </LinkButton>
                </div>
              </NavContentsMobile>
              <Hamburger onClick={this.toggleNav} isTransparent={isTransparent}>
                <HamburgeBun isExpanded={this.state.isExpanded} />
                <div>
                  <HamburgerVeggie isExpanded={this.state.isExpanded} />
                  <HamburgerPatty isExpanded={this.state.isExpanded}>Menu</HamburgerPatty>
                </div>
                <HamburgeBun isExpanded={this.state.isExpanded} />
              </Hamburger>
              <NavContentsDesktop span={[10]} isTransparent={isTransparent}>
                <ul>
                  <li>
                    <a href="/nonprofits">{this.str.navNonprofits}</a>
                  </li>
                  <li>
                    <Link to="/#who-we-are" smooth scroll={this.handleLinkClick}>
                      {this.str.navWho}
                    </Link>
                  </li>
                  <li>
                    <Link to="/#contact-us" smooth scroll={this.handleLinkClick}>
                      {this.str.navContact}
                    </Link>
                  </li>
                </ul>
                <LinkButton
                  to="/donate"
                  color={isTransparent ? colors.gray.light : undefined}
                  colorhover={isTransparent ? colors.gray.dark : undefined}
                  backgroundcolor={isTransparent ? "transparent" : undefined}
                  backgroundcolorhover={isTransparent ? colors.gray.light : undefined}
                  outlinecolor={isTransparent ? colors.gray.light : undefined}
                  onClick={this.handleDonateButtonClick}
                >
                  {this.str.navDonate}
                </LinkButton>
              </NavContentsDesktop>
            </Grid>
          </PrimaryNav>
        </PrimaryNavContainer>
      </>
    );
  }

  private handleWindowResize = (): void => {
    if (this.state.isExpanded) {
      this.setNavExpandedState(false);
    }
  };

  private generateLangButton = (): JSX.Element => {
    return <button onClick={this.changeLang}>{this.str.navLangSwitch}</button>;
  };

  private changeLang = (): void => {
    const lang: string = this.state.lang === "en" ? "id" : "en";

    this.setState({ lang });

    document.cookie = "lang=" + lang;

    window.location.reload();
  };

  private toggleNav = (): void => {
    const isExpanded: boolean = this.state.isExpanded;

    this.setNavExpandedState(!isExpanded);
  };

  private setNavExpandedState = (isExpanded: boolean): void => {
    this.setState({
      isExpanded: isExpanded,
    });

    if (this.props.onNavToggle) {
      this.props.onNavToggle(isExpanded);
    }
  };

  private handleLinkClick = (e: HTMLElement): void => {
    this.setNavExpandedState(false);
    this.scrollTo(e.id ?? "");
  };

  private handleDonateButtonClick = (): void => {
    this.setNavExpandedState(false);
    this.scrollTo("");
  };

  private scrollTo = (id: string): void => {
    const scrollContainer: HTMLElement | null = document.getElementById("app");

    if (!scrollContainer) {
      return;
    }

    scrollContainer.scroll({
      top: (document.getElementById(id)?.offsetTop ?? 0) - navHeight,
      left: 0,
      behavior: "smooth",
    });
  };
}
