/**
 * Breakpoints
 */
const vp0Value = 0;
const vp1Value = 320;
const vp2Value = 540;
const vp3Value = 768;
const vp4Value = 1024;
const vp5Value = 1400;
const vp6Value = 1779;
const vpMaxValue = 2048;

export const breakpoints = {
  vp0Value: vp0Value,
  vp1Value: vp1Value,
  vp2Value: vp2Value,
  vp3Value: vp3Value,
  vp4Value: vp4Value,
  vp5Value: vp5Value,
  vp6Value: vp6Value,
  vpMaxValue: vpMaxValue,
  vp0: `${vp0Value}px`,
  vp1: `${vp1Value}px`,
  vp2: `${vp2Value}px`,
  vp3: `${vp3Value}px`,
  vp4: `${vp4Value}px`,
  vp5: `${vp5Value}px`,
  vp6: `${vp6Value}px`,
  vpMin: `${vp1Value}px`,
  vpMax: `${vpMaxValue}px`,
  vp0Max: `${vp1Value - 1}px`,
  vp1Max: `${vp2Value - 1}px`,
  vp2Max: `${vp3Value - 1}px`,
  vp3Max: `${vp4Value - 1}px`,
  vp4Max: `${vp5Value - 1}px`,
  vp5Max: `${vp6Value - 1}px`,
  vp6Max: `${vpMaxValue}px`,
};

/**
 * Colors
 */
export const colors = {
  black: "#000",
  white: "#fff",
  gray: {
    lightExtra: "#fafafa",
    light: "#f1f1f1",
    lightMid: "#d1d1d1",
    base: "#919191",
    darkMid: "#484848",
    dark: "#282828",
    darkExtra: "#131313",
  },
  red: {
    electric: "#ff0808",
    light: "#e81010",
    base: "#d81313",
  },
};

const baseFontStack =
  "Raleway, 'Segoe UI', 'SegoeUI', -apple-system, BlinkMacSystemFont, 'Helvetica Neue', Helvetica, sans-serif";

const displayFontStack = `"Open Sans Condensed", "Bebas Neue", ${baseFontStack}`;

const serifFontStack = "Bitter, serif";

export const fonts = {
  base: baseFontStack,
  display: displayFontStack,
  serif: serifFontStack,
};

/**
 * Transitions
 */

export const transitions = {
  fast: "100ms ease-out",
  medium: "200ms ease-out",
};

/**
 * Box shadows
 */
export const shadows = {
  light: {
    static: "0px 6px 24px rgba(0, 0, 0, 0.18)",
    rest: "0  2px 4px rgba(0, 0, 0, .06), 0 .5px 1px rgba(0, 0, 0, .05)",
    hover: "0 19px 43px rgba(0, 0, 0, .22), 0 4px 11px rgba(0, 0, 0, .18)",
  },
  dark: {
    static: "0px 8px 28px rgba(0, 0, 0, 0.48);",
    rest: "0  2px 4px rgba(0, 0, 0, .18), 0 .5px 1px rgba(0, 0, 0, .15)",
    hover: "0 19px 43px rgba(0, 0, 0, .66), 0 4px 11px rgba(0, 0, 0, .54)",
  },
};

/**
 * Styles
 */
export const styles = {
  borderRadius: "4px",
};
