import React from "react";
import styled from "styled-components";
import ComponentBase from "../component-base";
import { colors, breakpoints } from "../assets/variables/style-variables";
import { Page, Grid, Column } from "./grid";
import { SectionTitle } from "./section-title";
import { ContentCard } from "./content-card";
import Card1Image from "../assets/images/project-tile-1.jpg";
import Card2Image from "../assets/images/project-tile-2.jpg";

const ProjectsContainer = styled.div`
  padding: 48px 0;
  background-color: ${colors.gray.lightMid};

  @media (min-width: ${breakpoints.vp2}) {
    padding: 84px 0;
  }
`;

const Description = styled.div`
  margin-top: 30px;
`;

const ProjectColumn = styled(Column)`
  > a {
    margin: 12px 0;
  }
`;

export class Projects extends ComponentBase {
  public render(): JSX.Element {
    return (
      <ProjectsContainer id="what-we-do">
        <Page>
          <Grid>
            <Column span={[12, 12, 12, 12, 3]}>
              <Description>
                <SectionTitle text={this.str.projectsHeading} />
                <p>{this.str.projectsSubheading}</p>
              </Description>
            </Column>
            <ProjectColumn span={[12, 12, 12, 6, 4]} position={[1, 1, 1, 1, 5]}>
              <ContentCard
                title={this.str.projectsGivingTitle}
                description={this.str.projectsGivingDescription}
                imageUrl={Card1Image}
                imageAltText={this.str.projectsGivingImgAlt}
                cta={this.str.projectsCtaDonate}
                href="/donate"
              />
            </ProjectColumn>
            <ProjectColumn span={[12, 12, 12, 6, 4]}>
              <ContentCard
                title={this.str.projectsCharityTitle}
                description={this.str.projectsCharityDescription}
                imageUrl={Card2Image}
                imageAltText={this.str.projectsCharityImgAlt}
                cta={this.str.projectsCtaExplore}
                href="/nonprofits"
              />
            </ProjectColumn>
          </Grid>
        </Page>
      </ProjectsContainer>
    );
  }
}
